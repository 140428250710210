import { CONSTANTS } from "../actions";
//import { SET_ACTIVE_PROJECT } from '../actions/types'

const initialState = null;

// storing data of active projects
const activeProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_ACTIVE_PROJECT: {
      return action.payload;
    }
    default:
      return state;
  }
  switch (action.type) {
    case CONSTANTS.ADD_PROJECT: {
      return action.payload;
    }
    //console.log(state);
    default:
      return state;
  }
  switch (action.type) {
    case CONSTANTS.DELETE_PROJECT: {
      return action.payload;
    }
    //console.log(state);
    default:
      return state;
  }
};

export default activeProjectReducer;
