import React, { useState } from "react";
import { Input } from 'reactstrap';
import styles from "../org.project.scss";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { editProject, editProjectTwo } from "../actions";
import MultiSelect from  'react-multiple-select-dropdown-lite';
import  'react-multiple-select-dropdown-lite/dist/index.css';
import Select from 'react-select';
import CollaborationButton from "./CollaborationButton";
import styled from "styled-components";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

//import Button from "@material-ui/core/Button";
/*const options = [
  { value:  'Epic', label: 'Epic' },
  { value:  'Spike', label: 'Spike'  },
  { value:  'Story', label: 'Story' },
  { value:  'Themes', label: 'Themes' },
  { value:  'Initiative', label: 'Initiative' },
  { value:  'Features', label: 'Features' },
  { value:  'Capability', label: 'Capability' },
  { value:  'Sub Epic', label: 'Sub Epic' }

    //navigate(`/${newID}/CardConfiguration/`);
];*/

// STYLES
const ContextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 470px;
  margin-top: 10px;
`;

const CardConfiguration= ({ organizations, cardConfig, projects, orgcounters, orgcounterOrder, index, dispatch, match, props }) => {
  const [newConfiguration, setNewConfiguration] = useState("");
  // Collapse isOpen State
  const [isOpen, setIsOpen] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [valued, setvalue] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [imageName, setImageName] = useState("");
  const { projectID } = useParams();
  const project = projects[projectID];
  const navigate = useNavigate();
  // Converting Object into array
  const optionValues = Object.values(cardConfig);
//console.log(cardConfig, optionValues);

  const handleOnSubmit = e => {
  }
  const handleNewSubmit = e => {
    if (newConfiguration === "") {
      alert("Input field cannot be empty");
    } else {
      let newConfig = newConfiguration.toUpperCase();

      dispatch(editProjectTwo(projectID, newConfig));
      setNewConfiguration("");

    }
  }

  const handleSubmit = e => {
    if (selectedOption === undefined) {
      const selectedOption = ['EPIC', 'SPIKE', 'STORY'];
      dispatch(editProject(projectID, selectedOption));
      navigate(`/${projectID}/Project/`);
    } else {
      dispatch(editProject(projectID, selectedOption));
      navigate(`/${projectID}/Project/`);

    }
  }

  const handleChange = (e) => {
    setSelectedOption(Array.isArray(e) ? e.map(x => x.value) : []);

   };

  const handleOnChange = e => {
    setNewConfiguration(e.target.value)
  }
  const onChangeImage = e => {
    setImageName(e.target.files[0])
  }
  console.log(imageName);


  const renderCardOption = () => {
    return(
      <div>
        <form onSubmit={handleSubmit} encType="multipart/form-data" style={{ textAlign: "center" }}>
          <div className="createInput">
            {/*<ContextContainer>
              <Select
                className="createInput"
                required
                defaultValue={[optionValues[0], optionValues[1], optionValues[2]]}
                onChange={handleChange}
                options={optionValues}
                isMulti
                isSearchable
              />
            </ContextContainer>*/}
            <ReactMultiSelectCheckboxes
            defaultValue={[optionValues[0], optionValues[1], optionValues[2]]}
            required
            onChange={handleChange}
            options={optionValues}
            isMulti />
          </div>
          <div style={{ marginLeft: "0%", marginTop: "20px", marginBottom: "20px" }}>
          {/* remove next page navigation*/}
            <CollaborationButton onClick={handleSubmit}>SELECT CARD TYPE & Continue</CollaborationButton>
          </div>
        </form>
      </div>
    )
  }


  // on click close editing
  const renderCardInput = () => {
    return (
      <div>
        <form style={{ textAlign: "center" }}>
          <div className="createInput">
            <Input
              //required
              className="cardinputbox"
              onChange={handleOnChange}
              value={newConfiguration}
              placeholder="Add new Configuration"
              type="text"
            />
          </div>
          <CollaborationButton className="createInput" onClick={handleNewSubmit}>Add new Card type</CollaborationButton>
        </form>
        <div className="submit-btn" >
{/*        <Button onClick={handleSubmit}>Continue</Button>
*/}        </div>
      </div>
    );
  };
const renderHeader = () => {
  return (
    <div style={{display: 'block', width: "100%"}}>
      <Navbar color="light" light expand="lg">
        <NavbarBrand align='left'>{project.title}</NavbarBrand>
        <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
        <Collapse isOpen={isOpen} navbar>
        </Collapse>
      </Navbar>
    </div>

  );
};




  return (
    <div className="mainContainer">
        {renderHeader()}
        {renderCardOption()}
        {renderCardInput()}

    </div>
  );

}
// setting up state of elements
const mapStateToProps = state => ({
  //lists: state.lists,
//  cards: state.cards,
//  organizations: state.organizations,
  projects: state.projects,
  cardConfig: state.cardConfig
});

export default connect(mapStateToProps)(CardConfiguration);
