import React from "react";
import styles from "../org.project.scss";
import Button from 'react-bootstrap/Button';


const ThumbnailPopup = props => {
  return (
    <div className="popup-boxedit">
      <div className="box">
        {/*<span className="close-icon" onClick={props.closeForm}>x</span>*/}
        {props.content}
        <Button variant="danger" onClick={props.closeCard}>Cancel</Button>{' '}
        {/*<button className="cancelButton" onClick={props.closeForm}>Cancel</button>*/}
      </div>
    </div>
  );
};

export default ThumbnailPopup;
