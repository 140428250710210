/*import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import { createGlobalStyle } from "styled-components";
import $ from "jquery";
import { PersistGate } from "redux-persist/integration/react";


const GlobalStyle = createGlobalStyle`
  html {
    background-color: skyblue;
    box-sizing: border-box;
    transition: all 0.5s ease-in;
  }
`;
const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GlobalStyle />
      <App />
    </PersistGate>
  </Provider>,
  //document.getElementById("root")
);

$(document).bind("DOMNodeRemoved", function(e) {
  console.log("Removed: " + e.target.nodeName);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();*/
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createGlobalStyle } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';

const GlobalStyle = createGlobalStyle`
  html {
    background-color: skyblue;
    box-sizing: border-box;
    transition: all 0.5s ease-in;
  }
`;

// Function to observe removed nodes
const ObserveRemovedNodes = () => {
  const observer = new MutationObserver((mutationsList) => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
        console.log('Removed:', mutation.removedNodes[0].nodeName);
        // Handle removed nodes here
      }
    }
  });

  observer.observe(document, { childList: true, subtree: true });
};

// Component
const Index = () => {
  ObserveRemovedNodes();

  const root = createRoot(document.getElementById('root'));

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyle />
        <App />
      </PersistGate>
    </Provider>
  );
};

serviceWorker.unregister();

// Render the component
const root = createRoot(document.getElementById('root'));
root.render(<Index />);
