import React, { useState, useEffect } from "react";
import ProjectThumbnail from "./ProjectThumbnail";
//import Example from "./ReactBootstrap";
import { Form, Input, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavLink } from 'reactstrap';
import styles from "../org.project.scss";
import Button from 'react-bootstrap/Button';
import Icon from "@material-ui/core/Icon";
//import CollaborationProject from "./CollaborationProject";
import { addProject, addList } from "../actions";
import { connect } from "react-redux";
//import CollaborationCreate from "./CollaborationCreate";
//import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { setActiveOrganization } from "../actions";
import { Link, useNavigate, useParams } from "react-router-dom";
import {v4 as uuid} from "uuid";
import Popup from './Popup';

//import Button from "@material-ui/core/Button";


// styling
const Title = styled.h4`
text-decoration: none;
`;
const OpenFormButton = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  height: 36px;
  margin-left: 8px;
  width: 300px;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 1;
  color: inherit;
  background-color: rgba(0,0,0,.35);
`;
const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-left: 5px;
`;
const StyledIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer;
`;

/*const Thumbnail = styled.div`
  flex: 1;
  height: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
const ButtonContainer = styled.div`
  display: absolute;
  flex-direction: column;
`;
const CreateTitle = styled.h3`
  font-size: 42px;
  color: white;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
`;
const ProjectTitle = styled.h3`
  font-size: 36px;
  margin-top: 5px;
  color: black;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
`;
const CreateInput = styled.input`
  width: 260px;
  height: 100px;
  font-size: 22px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  border: none;
  outline-color: blue;
  box-shadow: 0 2px 4px grey;
  align-self: center;
  border-radius: 25px;
`;
const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;
const MainContainer = styled.div`
  margin: 0 0 8px 0;
  position: relative;
  max-width: 100%;
  word-wrap: break-word;
`;
const StyledButton = styled(Button)`
  && {
    width: 100px;
    height: 40px;
    font-size: 12px;
    padding: 2px;
    margin:  2px 0 20px 12px;
    border-radius: 3px;
    color: white;
    background: green;
    box-shadow: 0 2px 4px grey;
    align-self: right;
    border-radius: 5px;
  }
`;
const BackButton = styled(Button)`
  && {
    width: 100px;
    height: 40px;
    font-size: 12px;
    padding: 2px;
    margin:  2px 0 2px 12px;
    border-radius: 3px;
    color: white;
    background: green;
    box-shadow: 0 2px 4px grey;
    align-self: left;
    border-radius: 5px;
  }
`;
*/

// TODO: Fix performance issue

const CollaborationOrganization= ({ organizations, projects, auth, orgcounters, orgcounterOrder, dispatch, match, props }) => {
  const [newProjectTitle, setNewProjectTitle] = useState("");
  const [newProjectKey, setNewProjectKey] = useState("");
  // Collapse isOpen State
  const [isOpen, setIsOpen] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { organizationID } = useParams();
  const organization = organizations[organizationID];



  //console.log(organizations.projectCounter);
  //const tt   = organizations.projectCounter++;
  //console.log(tt);

  /*/ to capitalize words and taking initial word to capitalize them
  var str = "Aava Script Object Notation";
  var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  var acronym = matches.join(''); // JSON

  console.log(acronym)
  const checktext = newProjectTitle.toUpperCase();
  console.log(checktext);
  */

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    dispatch(setActiveOrganization(organizationID))
  }, []);


  const navigate = useNavigate();
  const projectOrder = organization.projects;

  //const test = organization.projCounter++;
  const handleSubmit = e => {
    e.preventDefault();
    if (newProjectTitle === "" || newProjectTitle === " ") {
      alert("Please enter Title to proceed");
    } else {
      setIsEditing(false);
      const id = uuid();
      const newID = `project-${id}`;
      const test = organization.projCounter++;
      const setProjID = organization.projCounter;

      // Lists Insertion
      const frID = uuid();
      const secID = uuid();
      const thrID = uuid();
      //list inerstion data
      let projKey = newProjectKey.toUpperCase();


      dispatch(addProject(frID, secID, thrID, newProjectTitle, setProjID, newID, projKey /*, orgObjID*/));
      dispatch(addList(null, null, frID, secID, thrID ));
      setNewProjectTitle("");
      setNewProjectKey("");
      navigate(`/${newID}/CardConfiguration/`);
    }
    //
  };
  const handleTitleChange = e => {
    setNewProjectTitle(e.target.value);
    // code to set automatic key of project
    const check = e.target.value.toUpperCase();
    //function to make unique project key
    const key = check.match(/\b(\w)/g).join('').slice(0, 6);
    console.log(key.length);
    if (key.length <= 2) {
      function generateAlphabet(capital = true) {
      	return [...Array(26)].map((_, i) => String.fromCharCode(i +  (capital ? 65 : 97)));
      }
      const alphabet = generateAlphabet();
      const result = alphabet[Math.floor(Math.random() * alphabet.length)]+alphabet[Math.floor(Math.random() * alphabet.length)]+alphabet[Math.floor(Math.random() * alphabet.length)];
      //console.log(result);
      setNewProjectKey(result);
    } else if (key.length > 2) {

      setNewProjectKey(key);
    }
  };
  const handleKeyChange = e => {
    //setNewProjectKey(e.target.value);
    const result = e.target.value.replace(/[^a-z0-9]/gi, '');
    const message = result.slice(0, e.target.maxLength);

    setNewProjectKey(result);
  };

  const renderProjects = () => {
    return projectOrder.map(projectID => {
      const project = projects[projectID];
      if (!project) {
        return <p>Project not found</p>;
      }

      //  if (organization.softdelete === false) {
      {/*<div className="subContainer">*/}
      {/*</div>*/}
      return (
          <Title
            className="subContainer"
            organizationID={organizationID}
            projectKey={project.projectKey}
            projectCount={project.projectCount}
            key={projectID}
            style={{ textDecoration: "none" }}
            >
            <ProjectThumbnail {...project} />  {/*softdelete={organization.softdelete} in title*/}
            </Title>
        );

        //   }
      });
    };
  // getting current data of organizations, projects and cards
  const renderButton = () => {
    return (
      <div style={{display: 'block', width: "100%"}}>
        <Navbar color="light" light expand="lg">
          <NavbarBrand href="/" align='left'>{organization.title}</NavbarBrand>
          <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <Link className='btnadd' align="left" to="/Organization/SoftDeleted/" style={{ textDecoration: 'none', color: 'black'}}>Soft Deleted</Link>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }


  const renderCreateProject = () => {
    return (
      <div className="createInput" >
        {/*<OpenFormButton onClick={togglePopup}>
          <p style={{ flexShrink: 0 }}>Add New Project</p>
        </OpenFormButton>*/}
        <Button variant="primary" size="lg" onClick={togglePopup}>
          Add new Project
        </Button>{' '}
           {isOpen && <Popup
             content={<>
               <b>Add new Project</b>
               <Form style={{ textAlign: "center" }}>
                 {/*<FormText className="createinput">Create New Project</FormText>*/}
                 <div>
                 <p style={{ marginTop: "20px", textAlign: "left" }} >Project Name</p>
                   <Input
                     required
                     className="inputbox"
                     onChange={handleTitleChange}
                     value={newProjectTitle}
                     placeholder="Create new Project"
                     type="text"
                   />
                   <p style={{ textAlign: "left" }} >Project Key</p>
                   <Input
                     required
                     className="keybox"
                     onChange={handleKeyChange}
                     value={newProjectKey}
                     maxLength={6}
                     placeholder="Enter Project Key"
                     type="text"
                   />
                 </div>
               </Form>
               <Button variant="success" onClick={handleSubmit}>Add Project</Button>{' '}

               {/*<button className="saveButton" onClick={handleSubmit}>ADD PROJECT</button>*/}
             </>}
             handleClose={togglePopup}
           />}

      </div>
  );
  }
  return (
    <div className="mainContainer">
        {renderButton()}
        {renderProjects()}
      {renderCreateProject()}
    </div>

  );

}
// setting up state of elements
const mapStateToProps = state => ({
  lists: state.lists,
  projects: state.projects,
  cards: state.cards,
  organizations: state.organizations
});

export default connect(mapStateToProps)(CollaborationOrganization);
