import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import styles from '../../mystyle.module.scss';
import { isEmail } from "validator";
import PasswordShowHide from './PasswordSH';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [inchng, setInchng] = useState(false);
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const errors = useSelector(state => state.auth.errors);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const validEmail = () => {
    if (!isEmail(email)) {
      setInchng(true);
    }
  };

  const onChangeValueHandler = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    const userData = {
      userId: email,
      password: password,
      errors: errors
    };

    dispatch(loginUser(userData));
  };

  return (
    <>
      <form name="loginf" method="post" onSubmit={handleSubmit}>
        <div className={styles.container}>
          <div>
            {submitted && !email && (
              <div className="help-block" style={{ color: 'firebrick' }}>
                Email is required!
              </div>
            )}
            {inchng && !isEmail(email) && (
              <div className="help-block" style={{ color: 'firebrick' }}>
                Please Enter Valid Email
              </div>
            )}
            {submitted && errors && (
              <div className="help-block" style={{ color: 'firebrick' }}>
                {JSON.stringify(errors).slice(1, -1)}
              </div>
            )}
            <label htmlFor="lnm" className={styles.lblM}>
              Email Id :
            </label>
            <input
              type="text"
              name="email"
              value={email}
              placeholder="Enter Email Id"
              className={styles.inM}
              onChange={handleChange}
              onBlur={validEmail}
            />
          </div>

          <div>
            {submitted && !password && (
              <div className="help-block" style={{ color: 'firebrick' }}>
                Password is required!
              </div>
            )}
            <label className={styles.lblM}> Password :</label>
            <PasswordShowHide value={password} onChangeValue={onChangeValueHandler} />
          </div>
          <button type="submit" className={styles.btnM}>
            Login
          </button>
        </div>
      </form>
    </>
  );
}

export default Login;
