import { CONSTANTS } from "../actions";
import axios from 'axios';
//import uuid from "uuidv4";

const initialState = null;

// handling project/list add/delete and drag function
const projectsReducer = (state = initialState, action) => {
  switch (action.type) {

    case CONSTANTS.ADD_LIST: {
      // clean the code with removing title and id if not used
      const { projectID, id, listCounter,  frID, secID, thrID  } = action.payload;
      if (frID === undefined && secID === undefined &&  thrID === undefined) {
        const project = state[projectID];
        const newListID = `list-${id}`;
        const newLists = [...project.lists, newListID];
        project.lists = newLists;
        project.listCounter = listCounter;
        const insertList = [newListID];
        axios.put('http://localhost:4000/projects/edit/'+projectID, insertList)
          .then((res) => {
            console.log('data successfully updated');
          });

          const updateCounter = {
            listCounter: listCounter
          };
          axios.put('http://localhost:4000/projects/insertCounter/'+projectID, updateCounter)
            .then((res) => {
              console.log('Counter updated');
            });


        return { ...state, [projectID]: project };
      }
      else {
        return state;
      }
    }

    case CONSTANTS.ADD_CARD: {
      //Card ID counter in project
      const { projectID, cardCounter, projectKeyCounter } = action.payload;
      const project = state[projectID];
      project.cardCounter = cardCounter;
      const updateCounter = {
        cardCounter: cardCounter,
        projectKeyCounter: projectKeyCounter
      };
        axios.put('http://localhost:4000/projects/insertCounter/'+projectID, updateCounter)
          .then((res) => {
            console.log('Counter updated');
          });


      return { ...state, [projectID]: project };
    }
    case CONSTANTS.EDIT_CARDIMPEDIMENT: {
      const { projectID, parentID, flagged, id } = action.payload;
      const project = state[projectID];

      // remove ID if false
      if (flagged === false) {
        const dependentID = project.dependentID;
        const newdependentID1 = dependentID.filter(id => id !== parentID);
        const newdependentID = newdependentID1.filter(td => td !== id);
        project.dependentID = newdependentID;
        //console.log(state[projectID]);

        const delImped = [parentID, id];
        axios.put('http://localhost:4000/projects/pull-Impediment/'+projectID, delImped)
            .then((res) => {
              console.log('Impediment successfully Removed');
            });

        return { ...state, [projectID]: project };

      } // add ID if true
       else if (flagged === true) {
        const newImpediment = [...project.dependentID, parentID, id];
        project.dependentID = newImpediment;
        //console.log(state[projectID]);
        const addImped = [parentID, id];
        axios.put('http://localhost:4000/projects/add-Impediment/'+projectID, addImped)
            .then((res) => {
              console.log('Impediment successfully Removed');
            });

        return { ...state, [projectID]: project };

      }
    }

    case CONSTANTS.DRAG_HAPPENED: {
      const { projectID } = action.payload;
      const project = state[projectID];
      const lists = project.lists;
      const {
        droppableIndexEnd,
        droppableIndexStart,

        type
      } = action.payload;
      //console.log(action.payload);
      // draggin lists around
      if (type === "list") {
        const pulledOutList = lists.splice(droppableIndexStart, 1);
        lists.splice(droppableIndexEnd, 0, ...pulledOutList);
        project.lists = lists;
        const updateLists = {
          lists: lists
        };
        axios.put('http://localhost:4000/projects/editproject/'+projectID, updateLists)
          .then((res) => {
            console.log('Lists Position Updated');
          });

        console.log(project);
        return { ...state, [projectID]: project };
      }
      return state;
    }
    case CONSTANTS.DELETE_LIST: {
      const { listID, projectID } = action.payload;
      const project = state[projectID];
      const lists = project.lists;
      const newLists = lists.filter(id => id !== listID);
      project.lists = newLists;
      return { ...state, [projectID]: project };
    }





    case CONSTANTS.ADD_PROJECT: {
      const { title, newID, projID, frID, secID, thrID, organizationID, projectKey  } = action.payload;
      //const newID = `project-${id}`;
      const listcount = 1003;
      const cardcount = 1000;
      const projectCount = "001";
      const projectKeyCounter = 0;
      // lists unique ID
      var dat_e = new Date();
      dat_e = dat_e.toISOString().replace(/[^0-9]/g, "").slice(2, 8);
      const firstList = "List"+dat_e+1001;
      const secondList = "List"+dat_e+1002;
      const thirdList = "List"+dat_e+1003;


      const newProject = {
        id: newID,
        title,
        lists: [`list-${frID}`, `list-${secID}`, `list-${thrID}`],
        cardConfigurations: [],
        projectsID: projID,
        listCounter: listcount,
        cardCounter: cardcount,
        organization: organizationID,
        projectKey: projectKey,
        projectCount: projectCount,
        projectKeyCounter: projectKeyCounter,
        dependentID: []
      };
      const projectAdd = {
        _id: newID,
        title,
        lists: [`list-${frID}`, `list-${secID}`, `list-${thrID}`],
        cardConfigurations: [],
        projectsID: projID,
        listCounter: listcount,
        cardCounter: cardcount,
        organization: organizationID,
        projectKey: projectKey,
        projectCount: projectCount,
        projectKeyCounter: projectKeyCounter,
        dependentID: []
      };
      axios.post('http://localhost:4000/projects', projectAdd)
        .then((res) => {
          console.log('data successfully inserted');
      });

      const initialLists = [
         {
          _id: `list-${frID}`,
          cards: [],
          title: "Todo",
          project: newID,
          listsID: firstList
        },
         {
          _id: `list-${secID}`,
          cards: [],
          title: "In Progress",
          project: newID,
          listsID: secondList
        },
         {
          _id: `list-${thrID}`,
          cards: [],
          title: "Done",
          project: newID,
          listsID: thirdList
        },
      ];

      axios.post('http://localhost:4000/lists', initialLists)
        .then((res) => {
          console.log('List Inserted successfully');
        });
      const newState = { ...state, [newID]: newProject };
      return newState;

    }

    case CONSTANTS.EDIT_PROJECT_TITLE: {
      const { projectID, newTitle, newKey } = action.payload;

      const project = state[projectID];
      project.title = newTitle;
      project.projectKey = newKey;
      const newProjectTitle = {
          title: newTitle,
          projectKey: newKey
      }
      axios.put('http://localhost:4000/projects/editproject/'+projectID, newProjectTitle)
        .then((res) => {
          console.log('Project title Updated');
        });
      return { ...state, [projectID]: project };
    }
    //code to insert card configuration options list
    case CONSTANTS.EDIT_PROJECT: {
      const { projectID, selectedOption, newConfiguration } = action.payload;
      const project = state[projectID];
      //const newselectedOption = [...project.cardConfigurations, selectedOption];
      if (selectedOption === undefined ) {

        const newOptions = [...project.cardConfigurations, newConfiguration];
        project.cardConfigurations = newOptions;

        const insertCardOption = [newConfiguration];
        //const insertCardOption = [...project.cardConfigurations, [newConfiguration]];

        axios.put('http://localhost:4000/projects/inputOption/'+projectID, insertCardOption)
          .then((res) => {
            console.log('Card Configuration Updated');
          });
          return { ...state, [projectID]: project };
      } else {
        // old project.cardConfigurations = selectedOption;
        for (var i = 0; i < selectedOption.length; i++) {
          const newselectedOption = [...project.cardConfigurations, selectedOption[i]];
          project.cardConfigurations = newselectedOption;
        }

        axios.put('http://localhost:4000/projects/inputOption/'+projectID, selectedOption)
          .then((res) => {
            console.log('Card Configuration Updated');
          });
          return { ...state, [projectID]: project };
      }
      //project.lists = newLists;

    }

    case CONSTANTS.DELETE_PROJECT: {
      const { projectID, organizationID } = action.payload;
      const newState = state;
      delete newState[projectID];
      axios.delete('http://localhost:4000/projects/delete-project/'+projectID )
        .then((res) => {
          console.log('Project title Deleted successfully');
      });
      const delProject = [projectID];
      axios.put('http://localhost:4000/organizations/pull-projectid/'+organizationID, delProject)
        .then((res) => {
          console.log('Project ID successfully Removed');
      });

      return newState;
    }
    case CONSTANTS.FETCH_PROJECT: {
      const { newID, newProject }  = action.payload;
      return { ...state, [newID]: newProject };
    }
     default:
      return state;
  }
};


export default projectsReducer;
