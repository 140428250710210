import { CONSTANTS } from "../actions";

const initialState = [];

// handling orgcounter add update and delete and saving data
const orgcountersReducer = (state = initialState, action) => {

  switch (action.type) {
    case CONSTANTS.ADD_ORGCOUNTER: {
      const { orgID, orgcounterID } = action.payload;

  return { ...state, [`${orgcounterID}`]: orgID };
}

case CONSTANTS.FETCH_ORGCOUNTER: {
  //console.log(action.payload);
  const { orgcounterID, newOrgId }  = action.payload;
  //console.log(orgcounterID);
  return { ...state, newOrgId, orgcounterID };
}

    default:
      return state;
  }
};

export default orgcountersReducer;
