import axios from "axios";
import setAuthToken from "../utils/setAuthToken.js";
import jwt_decode from "jwt-decode";

import{
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_CURRENT_USER_LOGOUT,
  SET_INVALID_USER,
  CLEAR_ERRORS,
  USER_UREG,
  RESEND_CODE,
   // USER_LOADING
} from "./types.js";
const apiUrl = 'https://sgscrum.sgagile.com';
//const apiUrl = 'http://localhost:4000';

//Register User
export const registerUser = (user) => dispatch =>
{
   //axios.post(`/Register/`, user)
   axios.post(`${apiUrl}/Register/`, user)
       .then(res=>{
         //  console.log('in res', res);
            if (res.data.success === true){
                //save to localStorage
                //set token to localstorage
                const {token} = res.data;
                    localStorage.setItem("jwtToken", token);
                    // set token to auth header
                    setAuthToken(token);
                    //decode token to get user data
                    const decoded = jwt_decode(token);
                    //set current user
                    console.log('reg',decoded);
                    dispatch(setRegUser(decoded));
            }
        })
        .catch((e) => {
            // Check if e.response exists before accessing its properties
            if (e.response && e.response.data) {
                dispatch({
                    type: GET_ERRORS,
                    payload: e.response.data
                });
            } else {
                // Handle network errors or other unexpected errors
                console.log('Error:', e.message);
            }
        });
};
//reg user after code
export const regUser = (data) => dispatch =>
{
    console.log('inreguser action',data);
   //axios.post(`/AuthReg/`, data)
   axios.post(`${apiUrl}/AuthReg/`, data)
        .then(res=>{
           console.log('in registerP', res);
            if (res.data.success === true){
                //save to localStorage
                //set token to localstorage
                const {token} = res.data;
                    localStorage.setItem("jwtToken", token);
                    // set token to auth header
                    setAuthToken(token);
                    //decode token to get user data
                    const decoded = jwt_decode(token);
                    //set current user
                    console.log('deco',decoded);
                    dispatch(setCurrentUser(decoded));
            }
        })
        .catch((e) =>{
            console.log(e.response.data,'dd');
             dispatch({
                type: GET_ERRORS,
                payload: e.response.data
                })
        });
};
export const resendCode = (user) => dispatch =>
{
   //axios.post(`/ResendCode/`, user)
   axios.post(`${apiUrl}/ResendCode/`, user)
        .then(res=>{
            if (res.data.success === true){
                    dispatch(setResendCode(res.data.success));
            }
        })
        .catch((e)=>{
          //  console.log(e.response.data,'dd');
             dispatch({
                type: GET_ERRORS,
                payload: e.response.data
                })
        });
};
// login -get user token
export const loginUser = (userData) => dispatch =>
{
  //  console.log('inlogin'); 
  //  axios.post(`/Login`, userData,
    axios.post(`${apiUrl}/Login`, userData)
    .then(res=>{
        //save to localStorage
        //set token to localstorage
        console.log('resuser',res.data);
        if (res.data.success === true){
            const {token} = res.data;
            localStorage.setItem("jwtToken", token);
            // set token to auth header
            setAuthToken(token);
            //decode token to get user data
            const decoded = jwt_decode(token);
            //set current user
            console.log('token decoded',decoded);
            if(decoded.isRegU===true)
               {console.log('reg');
                 dispatch(setCurrentUser(decoded));
               }
            else
                {console.log('not reg');
                    dispatch(setRegUser(decoded));
                }
        }
        })
        .catch((e) =>{
            console.log(e,'dd');
             dispatch({
                type: GET_ERRORS,
                payload: e.res.data
                })
        });
};

//set logged in user
export const setCurrentUser = decoded => {
   // console.log('in current user',decoded);
   return{
        type: SET_CURRENT_USER,
        payload: decoded
    };
};
//set under registration user
export const setRegUser = decoded => {
     console.log('in set reg',decoded);
    return{
         type: USER_UREG,
         payload: decoded
     };
 };

export const setResendCode = decoded => {
    console.log('in set resend',decoded);
   return{
        type: RESEND_CODE,
        payload: decoded
    };
};
//log user out
/*
export const logoutUser1 = (user) => dispatch => {
 //console.log('usr logout',user);
    axios.post('http://192.168.1.8:8000/Logout/', user)
    .then(res=>{
   //    console.log('in authaction logout', res);
        if (res.data.success === true){
     //       console.log('log auth', res.data);
            setAuthToken(false);
            localStorage.removeItem("jwtToken");
               //set current user to empty objext {} which will set isAuthenticated to false
            dispatch(UnsetCurrentUser(res.data));
        }
    })
    .catch((e)=>{
       // console.log(e.response.data,'dd');
         dispatch({
            type: GET_ERRORS,
            payload: e.res.data
            })
    });
};*/
export const logoutUser = (user) => dispatch => {
    setAuthToken(false);
    localStorage.removeItem("jwtToken");

    dispatch({
        type: SET_CURRENT_USER_LOGOUT,
        payload: {}
    });
}
/*export const UnsetCurrentUser = decoded => {
    //console.log('in unsetcurrent user',decoded);
    return{
        type: SET_CURRENT_USER_LOGOUT,
        payload: {}
    };
};
*/
export const setInvalidUser = () => error => {
    //console.log(error,'seterrorinvalid');
    return{
        type: SET_INVALID_USER,
        payload: error
    };
};

export const UnsetErrors = () => (dispatch) => {
    console.log('unseterrors');
    dispatch({
        type: CLEAR_ERRORS,
        payload: {}
    });
};
