import { CONSTANTS } from "../actions";
import axios from 'axios';

const initialState = null;

const listsReducer = (state = initialState, action) => {
  //console.log(state);
  switch (action.type) {
    case CONSTANTS.ADD_LIST: {
      const { title, id, projectID, listCountID, frID, secID, thrID } = action.payload;
      if (frID === undefined && secID === undefined && thrID === undefined) {
        const newList = {
          title: title,
          id: `list-${id}`,
          cards: [],
          listsID: listCountID
        };
        const newListAdd = {
          title: title,
          _id: `list-${id}`,
          cards: [],
          project: projectID,
          listsID: listCountID
        };
        //console.log(newListAdd);
        axios.post('http://localhost:4000/lists', newListAdd)
          .then((res) => {
            console.log('List Inserted successfully');
          });
        const newState = { ...state, [`list-${id}`]: newList };
        return newState;
        }
      else {
        // Lists Insertion
        const firstID = `list-${frID}`;
        const secondID = `list-${secID}`;
        const thirdID = `list-${thrID}`;
        var dat_e = new Date();
        dat_e = dat_e.toISOString().replace(/[^0-9]/g, "").slice(2, 8);
        const firstList = "List"+dat_e+1001;
        const secondList = "List"+dat_e+1002;
        const thirdList = "List"+dat_e+1003;
        //list inerstion data

        const listOne = {
          id: firstID,
          cards: [],
          title: "Backlog",
          listsID: firstList
        }
        const listTwo = {
          id: secondID,
          cards: [],
          title: "Ready",
          listsID: secondList
        }
        const listThree = {
          id: thirdID,
          cards: [],
          title: "Accepted",
          listsID: thirdList
        }
        const newState = { ...state, [firstID]: listOne, [`list-${secID}`]: listTwo, [`list-${thrID}`]: listThree };
        return newState;
      }
    }

    case CONSTANTS.ADD_CARD: {
      const { listID, id } = action.payload;
      const list = state[listID];
      console.log(list);
      list.cards.push(`card-${id}`);
      //imgCounter to check random images
      /*const newImgCounter = {
          imgCounter: imgCounter
      }*/

      const cardList = [`card-${id}`];
      axios.put('http://localhost:4000/lists/listcard/'+listID, cardList)
        .then((res) => {
          console.log('Card ID successfully Inserted');
        });
        // image counter update in database
      /*axios.put('http://localhost:4000/lists/editlist/'+listID, newImgCounter)
        .then((res) => {
          console.log('Image Counter Updated');
        });*/
      return { ...state, [listID]: list };
    }

    case CONSTANTS.DRAG_HAPPENED:
      const {
        droppableIdStart,
        droppableIdEnd,
        droppableIndexEnd,
        droppableIndexStart,
        draggableId,
        projectID,
        type
      } = action.payload;
      // draggin lists around - the listOrderReducer should handle this
      if (type === "list") {

        return state;
      }

      // in the same list
      if (droppableIdStart === droppableIdEnd) {
        //console.log(state);
        const list = state[droppableIdStart];
        const cards = list.cards;
        //old commented
        //const card = list.cards.splice(droppableIndexStart, 1);
        //list.cards.splice(droppableIndexEnd, 0, ...card);
        const card = cards.splice(droppableIndexStart, 1);
        cards.splice(droppableIndexEnd, 0, ...card);
        list.cards = cards;
        const updateCards = {
          cards: cards
        };
        axios.put('http://localhost:4000/lists/editlist/'+droppableIdStart, updateCards)
          .then((res) => {
            console.log('Card ID successfully updated');
          });

        return { ...state, [droppableIdStart]: list };
      }

      // other list
      if (droppableIdStart !== droppableIdEnd) {
        // find the list where the drag happened
        const listStart = state[droppableIdStart];
        // pull out the card from this list
        const card = listStart.cards.splice(droppableIndexStart, 1);
        // find the list where the drag ended
        const listEnd = state[droppableIdEnd];

        // put the card in the new list
        listEnd.cards.splice(droppableIndexEnd, 0, ...card);
        const cardID = [draggableId];
        axios.put('http://localhost:4000/lists/pullcardid/'+droppableIdStart, cardID)
          .then((res) => {
            console.log('Card ID successfully Removed');
          });
        axios.put('http://localhost:4000/lists/listcard/'+droppableIdEnd, cardID)
          .then((res) => {
            console.log('Card ID successfully Updated');
          });
          //const cardList = [droppableIdEnd]
          const cardList = {
              list: droppableIdEnd
          }
        axios.put('http://localhost:4000/cards/editcard/'+draggableId, cardList)
          .then((res) => {
            console.log('List ID in Card data successfully Updated');
          });

        return {
          ...state,
          [droppableIdStart]: listStart,
          [droppableIdEnd]: listEnd
        };
      }
      return state;

    case CONSTANTS.DELETE_CARD: {
      const { listID, id } = action.payload;

      const list = state[listID];
      const newCards = list.cards.filter(cardID => cardID !== id);

      return { ...state, [listID]: { ...list, cards: newCards } };
    }

    case CONSTANTS.EDIT_LIST_TITLE: {
      const { listID, newTitle } = action.payload;

      const list = state[listID];
      list.title = newTitle;
      const newListTitle = {
          title: newTitle
      }
      axios.put('http://localhost:4000/lists/editlist/'+listID, newListTitle)
        .then((res) => {
          console.log('List title Updated');
        });
      return { ...state, [listID]: list };
    }

    case CONSTANTS.DELETE_LIST: {
      const { listID, projectID } = action.payload;
      const newState = state;
      delete newState[listID];
      axios.delete('http://localhost:4000/lists/delete-list/'+listID )
        .then((res) => {
          console.log('List title Deleted successfully');
      });
      const delList = [listID];
      axios.put('http://localhost:4000/projects/pull-listid/'+projectID, delList)
        .then((res) => {
          console.log('List ID successfully Removed');
      });
      return newState;
    }
    case CONSTANTS.FETCH_LIST: {
      const { newID, newList }  = action.payload;
      return { ...state, [newID]: newList };
    }

    default:
      return state;
  }
};

export default listsReducer;
