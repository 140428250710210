import { CONSTANTS } from "../actions";
import { v4 as uuid } from 'uuid';
import axios from 'axios';

export const setActiveProject = id => {
  return {
    type: CONSTANTS.SET_ACTIVE_PROJECT,
    payload: id
  };
};
// dispatch actions of add project
export const addProject = ( frID, secID, thrID, title, setProjID, newID, projectKey ) => {
  return (dispatch, getState) => {
    const organizationID = getState().activeOrganization;
    //const projNewID = setProjID+1;
    //console.log(frID, secID, thrID, title, setProjID, newID, projectKey);
    const id = uuid();
    var dat_e = new Date();
    dat_e = dat_e.toISOString().replace(/[^0-9]/g, "").slice(2, 8);
    const projID = "Proj"+dat_e+setProjID;

    dispatch({
      type: CONSTANTS.ADD_PROJECT,
      payload: { title, projectKey, organizationID, newID, projID, setProjID, frID, secID, thrID }
    });
  };
};


// dispatch action of edit project title
export const editProjectTitle = ( projectID, newTitle, newKey) => {
  /*const id = uuid();
  var dat_e = new Date();
  const newCid = orgid++;
  dat_e = dat_e.toISOString().replace(/[^0-9]/g, "").slice(2, 8);
  const orid = "Org"+dat_e+newCid;*/
//  console.log(selectedOption, newTitle);
  return {
    type: CONSTANTS.EDIT_PROJECT_TITLE,
    payload: {
      projectID,
      newTitle,
      newKey
    }
  };
};
// dispatch action of edit project title
export const editProject = ( projectID, selectedOption) => {
  console.log(selectedOption);
  return {
    type: CONSTANTS.EDIT_PROJECT,
    payload: {
      projectID,
      selectedOption
    }
  };
};
export const editProjectTwo = ( projectID, newConfiguration) => {
  console.log(newConfiguration);
  return {
    type: CONSTANTS.EDIT_PROJECT,
    payload: {
      projectID,
      newConfiguration
    }
  };
};

//dispatch action of delete project
export const deleteProject = (projectID) => {
  return (dispatch, getState) => {
    const organizationID = getState().activeOrganization;
    return dispatch({
      type: CONSTANTS.DELETE_PROJECT,
      payload: {
        projectID,
        organizationID
      }
    });
  };
};

/*export const fetchProject = () => {
    return (dispatch) => {
        axios.get('http://localhost:4000/projects/')
        .then(res => {
            for (let item of res.data) {
            const newID = `${item._id}`;
            const newProject = {
              id: newID,
              title:item.title,
              cards: item.cards,
              organization: item.organization
            };
            console.log(newProject);
            dispatch({ type: CONSTANTS.FETCH_PROJECT, payload:{newID, newProject} })
          }
        })
    };
};
*/
