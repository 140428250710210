import { CONSTANTS } from "../actions";
//import { SET_ACTIVE_BOARD } from '../actions/types'

const initialState = null;

// storing data of active boards
const activeBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_ACTIVE_BOARD: {
      return action.payload;
    }

    default:
      return state;
  }
};

export default activeBoardReducer;
