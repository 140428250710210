import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Icon from "@material-ui/core/Icon";
import Textarea from "react-textarea-autosize";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CollaborationCreate from "./CollaborationCreate";
import  'react-multiple-select-dropdown-lite/dist/index.css';
import Select from 'react-select';
import { useNavigate, useParams } from "react-router-dom";
//import CollaborationTest from "./CollaborationTest";
import {Form, Input } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import CloseIcon from '@material-ui/icons/Close';


const Container = styled.div`
  width: 284px;
  margin-bottom: 8px;
  margin-top: 10px;
`;

const StyledCard = styled(Card)`
  min-height: 45px;
  padding: 6px 8px 2px;
`;

const StyledCards = styled(Card)`
  min-height: 50px;
  padding: 6px 8px 2px;
`;

const StyledTextArea = styled(Textarea)`
  resize: none;
  width: 100%;
  overflow: hidden;
  outline: none;
  border: none;
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-left: 5px;
`;

const StyledIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer;
`;


// this form gets data from CollaborationCreate and display it here and add styling
const CollaborationForm = React.memo(
  ({props, list, text = "", header = "", name = "", configtype, onChange, onTextChange, saveCard, onHeaderChange, onNameChange, onInputChange, onOptnChange, onAddClick, onRemoveClick, closeForm, children, label, projects, value }) => {
   //console.log(onChange);
   const [isSelected, setSelection] = useState(false);
   const { projectID } = useParams();
   const project = projects[projectID];
   const [required, setRequired] = useState({ required: true });

   const option = project.cardConfigurations;
   /*const opt =[
     { value:  project.cardConfigurations, label: project.cardConfigurations }
   ];
   console.log(opt);*/
   //const optiontst = Object.values(test);

   //console.log(test.length);

   /* make models value and label, send data into that labels
    using action, */
    const placeholder = list ? "Enter status title..." : "Enter card details...";
    //"Enter a title for this card..."


    if (list === true) {
      return (
        <div className="cardContainer">
          <div className="styledList">
            <Input
              className="styledTextArea"
              placeholder={placeholder}
              autoFocus
              value={text}
              onChange={e => onTextChange(e)}
              onBlur={closeForm}
            />
        </div>
          <ButtonContainer>
            {children}

            <StyledIcon onMouseDown={closeForm}><CloseIcon /></StyledIcon>
          </ButtonContainer>
        </div>
      );
    } else {
      //className="frmbtn"
      return (
        <div className="cardContainer" style={{ outline:"solid", outlineWidth: "thin", margin: "1px"}}>
          <div className="frmbtn">

               <b>Card Details</b>
               <Form  style={{ textAlign: "left" }}>
               <br/>
               <select className="optionInput" required defaultValue={configtype} onChange={(e) => onOptnChange(e)}>{
                 option.map( (selectedOptn,y) =>
                 <option key={y} value={selectedOptn}>{selectedOptn}</option> )
               }</select>
               <label>{configtype}</label>
               <div>
               <label ><i style={{ color: "red" }}>*</i>Title</label>
               <StyledCards>
                 {/*<Textarea
                   className="styledTextArea"*/}
                <StyledTextArea
                   type="text"
                   required
                   placeholder={configtype}
                   value={header}
                   onChange={e => onHeaderChange(e)}
                   autoFocus
                 />
             </StyledCards>
             </div>
             <div>
               <label>Card Description</label>
               <StyledCards>
                 <StyledTextArea
                   placeholder="card description"
                   onChange={e => onNameChange(e)}
                   value={name}
                 />
               </StyledCards>
             </div>
             <div>
               <label>Details</label>
               <StyledCards>
                 <StyledTextArea
                   placeholder={placeholder}
                   onChange={e => onTextChange(e)}
                   value={text}
                 />
               </StyledCards>
               </div>

                 </Form>

            {/*  test.map((x, i) => {
                return (
                  <option>{x}</option>
                  <div className="box">
                    <StyledCards>
                      <StyledTextArea
                        name="inputBox"
                        placeholder={x}
                        onChange={e => onInputChange(e, i)}
                        value={x.test}
                      />
                    </StyledCards>
                    {/*<div className="btn-box">
                      {test.length !== 1 && (
                        <button className="mr10" onClick={() => onRemoveClick(i)}>
                          Remove
                        </button>
                      )}
                      {test.length - 1 === i && (
                        <button onClick={()=> onAddClick()}>Add New</button>
                      )}
                    </div>*//*}
                  </div>
                );
              })*/}
              {/*<div style={{ marginTop: 20 }}>{JSON.stringify(inputBox)}</div>
                <CollaborationTest {...inputBox} />*/}

              <ButtonContainer onMouseDown={closeForm}>
                {children}
                <CloseIcon />
              </ButtonContainer>
        </div>
        </div>
      );

    }
  }
);

const mapStateToProps = state => ({
  projects: state.projects
});


export default connect(mapStateToProps)(CollaborationForm);
