import React, {useState, useEffect} from 'react';
import {useDispatch ,useSelector} from 'react-redux';
import { registerUser } from "../../actions/authActions.js";
import styles from '../../mystyle.module.scss';
import {isEmail} from "validator";
import Validator from "validator";
import {useNavigate} from "react-router-dom";

function Register(props){
  const [user, setUser] = useState({
    fName: '',
    lName: '',
    email: '',
    password: '',
    password2: ''

  });

  const [submitted, setSubmitted] = useState(false);
  const [inchng, setInchng] = useState(false);
  const [inpsw,setInpsw] = useState(false);
  const [inpswC,setInpswC] = useState(false);
  const [passw, setPassw] = useState(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/)
  const [hide, setHide] = useState(true);
  const dispatch= useDispatch();
  const errors = useSelector(state=> state.auth.errors)
  const isAuthenticated = useSelector(state=>state.auth.isAuthenticated)
  const isReg = useSelector(state=>state.auth.isReg)

  let navigate = useNavigate();

  useEffect(()=>{
    if(isReg){
      //console.log('reg',props);
      navigate('/RegisterP');
    }
  });
    const handleChange=(e)=>{
      e.preventDefault();
      setUser({ ...user, [e.target.name]: e.target.value });
      if(user.password2){
        setInpswC(true);
      }
      }
    const validEmail = () => {
      if(!isEmail(user.email))
       {
          setInchng(true);
          //  in1.current.focus();
        }
     }
     const hideSwitch = ev => {
      setHide(!hide)
  }

    const checkPassword=(e)=>{
      if(!user.password.match(passw)){
        console.log('not alphanumeric')
        setInpsw(true);
      }
      else
      setInpsw(false);

    }
     const handleSubmit = (e) =>{
        e.preventDefault();
        setSubmitted(true);
        setUser({ ...user, [e.target.name]: e.target.value });
        if(user.fName && user.lName && user.email && user.password && user.password2){
           dispatch(registerUser(user));
        }
      }

    const handleMainP=(e)=>{
      //e.preventDefault();
      navigate('/');
      }
     return (
       <>
         <button name="mainp" onClick={handleMainP} className={styles.btnM}>Home</button>

           <h1 className={styles.head1}>Sign Up</h1>
            <form method="post"onSubmit={handleSubmit}>
            <div className={styles.container}>
            <div className={'form-group' + (submitted && !user.fName ? ' has-error' : '')}>
            {submitted && !user.fName &&
                <div className="help-block"style={{color: 'firebrick', fontSize:'.9em'}}>First Name is Required</div>
              }
              <label className={styles.lblM}> First Name : </label>
              <input type="text" name="fName" placeholder="First Name" className={styles.inM} value={user.fName}  onChange={handleChange} />

            </div>
            <div className={'form-group' + (submitted && !user.lName ? ' has-error' : '')}>
              {submitted && !user.lName &&
                  <div className="help-block" style={{color: 'firebrick' , fontSize:'.9em'}}>Last Name is Required</div>
              }
              <label className={styles.lblM}> Last Name: </label>
              <input type="text" name="lName" placeholder='Last Name' className={styles.inM} onChange = {handleChange} value={user.lName} />

            </div>
            <div className={'form-group' + (submitted && !user.email ? ' has-error' : '')}>
                {(submitted && !user.email &&
                  <div className="help-block" style={{ color: 'firebrick' , fontSize:'.9em'}}>Email is required</div>) ||
                (inchng && !isEmail(user.email) &&
                  <div className="help-block" style={{ color: 'firebrick' , fontSize:'.9em'}}> Please Enter Valid Email </div> )||
                (errors.email &&
                    <div className="help-block" style={{ color: 'firebrick' , fontSize:'.9em'}}> Email Already Exists!</div> )
                }
                <label className={styles.lblM}>Email ID : </label>
                <input type="text" name="email" placeholder='Enter Email Id'  className={styles.inM} value={user.email}
                onChange = {handleChange}
                onBlur={validEmail}
                />
             </div>

            <div>
                {(submitted && !user.password &&
                  <div className="help-block" style={{ color: 'firebrick' , fontSize:'.9em'}}> Password is required</div>) ||
                (inpsw && user.password.length < 8 &&
                  <div className="help-block" style={{ color: 'firebrick' , fontSize:'.9em'}}> Password length cannot be less than 8</div>)||
                (inpsw && !user.password.match(passw) &&
                  <div className="help-block" style={{ color: 'firebrick' , fontSize:'.9em'}} >
                    Please enter alphanumeric password <br></br> with 1 uppercase & 1 lowercase  & 1
                    <br></br>special character</div> )
                }
                <label className={styles.lblM}> Password :</label>

                <div>   <input className={styles.inM} placeholder='Create Password' name='password' type={hide ? 'password' : 'input'}
                    value={user.password} onChange={handleChange}
                    onBlur={checkPassword} />
                    </div>
                <div>
                {(submitted && !user.password2 &&
                  <div className="help-block" style={{ color: 'firebrick' , fontSize:'.9em'}}>Password is required</div>) ||
                  (submitted && user.password2.length < 8  &&
                  <div className="help-block" style={{ color: 'firebrick' , fontSize:'.9em'}}>Password length cannot be less than 8</div> )||
                 (  inpswC && !Validator.equals(user.password, user.password2) &&
                  <div className="help-block" style={{ color: 'firebrick' , fontSize:'.9em'}}> Passwords don't Match </div> )
                }
                <label className={styles.lblM}> Confirm Password :</label>
                  <input className={styles.inM} placeholder='Confirm Password' name='password2' type={hide ? 'password' : 'input'}
                    value={user.password2} onChange={handleChange} /></div>
                    <div>
                        {
                        hide ?
                             <label style={{fontSize:'0.9em'}}><input className="styles.inM"  type="checkbox" id="pshow" name="pshow" value="Show Password"  onClick={hideSwitch}/> <span className="styles.lblM" >Show Password</span></label> :

                             <label style={{fontSize:'0.9em'}}><input type="checkbox" id="phide" name="phide" value="Hide Password"  onClick={hideSwitch}/>  <span className="styles.clbl">Hide Password</span></label>
                        }
                    </div>
                <div style = {{fontWeight :100, fontSize:'0.8em', align:'center'}}>
                    Please enter alphanumeric password<br></br>with 1 uppercase & 1 lowercase & 1
                    <br></br> special character</div>
            </div>
            <button name="signupB" type="submit" className={styles.btnM}>Create User</button>
            </div>
          </form>
                </>
     );
    }
export default  Register;
