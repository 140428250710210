import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import OrganizationThumbnail from "./OrganizationThumbnail";
import OrganizationShow from "./OrganizationShow";
import OrgButton from "./OrgButton";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";


const Thumbnail = styled.div`
  flex: 1;
  height: 50%;
  margin: 0 auto;
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  margin: 0 0 8px 0;
  position: left;
  max-width: 100%;
  word-wrap: break-word;
`;
/*const Thumbnailed = styled.div`
  flex: 1;
  height: 50%;
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  margin: 0 0 8px 0;
  position: relative;
  max-width: 100%;
  word-wrap: break-word;

`;*/
const Title = styled.h4`
  text-decoration: none;
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  box-sizing: border-box;
`;

const StyledButton = styled(Button)`
  && {
    width: 100px;
    height: 40px;
    font-size: 12px;
    padding: 2px;
    margin:  2px 0 20px 0;
    border-radius: 3px;
    color: white;
    background: green;
    box-shadow: 0 2px 4px grey;
    align-self: left;
    border-radius: 5px;
  }
`;


const useStyles = makeStyles({
  root: {
    minWidth: 10,
  },
});



const SoftDeleted = ({ dispatch, organizations, organizationOrder, organizationID }) => {
  const renderDeletedOrganizations = () => {
    return organizationOrder.map(organizationID => {
      const organization = organizations[organizationID];
      //console.log(organizations);
      //console.log(organization.softdelete);
      if (organization.softdelete === true) {
      return (
            <Title
              key={organizationID}
              Time={organization.Time}
              style={{ textDecoration: "none" }}
            >
            <OrganizationShow {...organization} />
            </Title>


      );

    }
    });
  };

  return (
    <HomeContainer>
      <Thumbnail>
        <h1>Organization</h1>
        {renderDeletedOrganizations()}
        <Link to="/" style={{ textDecoration: 'none'}}>
          <StyledButton >Back</StyledButton>
        </Link>
      </Thumbnail>
    </HomeContainer>
  );
};

const mapStateToProps = state => ({
  organizations: state.organizations,
  organizationOrder: state.organizationOrder
});

export default connect(mapStateToProps)(SoftDeleted);
