import React from "react";
import styles from "../org.project.scss";
import Button from 'react-bootstrap/Button';

const PopupCard = props => {
  return (
    <div className="popup-box">
      <div className="box">
        {props.content}
        <Button variant="secondary" onClick={props.handleClose}>Close</Button>{' '}
        {/*<button className="cancelButton" onClick={props.handleClose}>Cancel</button>*/}
      </div>
    </div>
  );
};

export default PopupCard;
