import React, { useState } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Textarea from "react-textarea-autosize";
import CardContent from "@material-ui/core/CardContent";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import Icon from "@material-ui/core/Icon";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CollaborationForm from "./CollaborationForm";
import { editCard, addCard, deleteCard } from "../actions";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CollaborationButton from "./CollaborationButton";
import { Link } from "react-router-dom";
import Popup from './Popup';
import PopupCard from './PopupCard';
import Button from 'react-bootstrap/Button';
import { Form, Input } from 'reactstrap';
import ThumbnailPopup from './ThumbnailPopup';
import PopupCreateCard from './PopupCreateCard';
import ToggleButton from "./ToggleButton";
import ToggleButtonDefault from "./ToggleButtonDefault";
import ToggleButtonDisplay from "./ToggleButtonDisplay";
import AddIcon from '@material-ui/icons/Add';

import { useNavigate, useParams } from "react-router-dom";
import CollaborationOpenForm from "./CollaborationOpenForm";
//import { Control } from 'react-redux-form';
//new styling
import uuid from "uuidv4";
import { makeStyles } from '@material-ui/core/styles';



//new style from card form
const StyledCards = styled(Card)`
  min-height: 50px;
  padding: 6px 8px 2px;
`;

const OpenFormButton = styled.div`
  display: relative;
  align-items: left;
  cursor: pointer;
  border-radius: 3px;
  height: 36px;
  margin-left: 1px;
  width: 300px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 0.5;
  color: inherit;
  background-color: inherit;
`;


const StyledTextArea = styled(Textarea)`
  resize: none;
  width: 100%;
  overflow: hidden;
  outline: none;
  border: none;
`;

const CardContainer = styled.div`
  margin: 0 0 8px 0;
  max-width: 100%;
  word-wrap: break-word;
`;

const EditButton = styled(Icon)`
  position: relative;
  display: none;
  left: 240px;
  top: 5px;
  opacity: 0.5;
  ${CardContainer}:hover & {
    display: flex;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
`;
const FlagButton = styled(Icon)`
  position: relative;
  display: none;
  color: #fa0510;
  left: 240px;
  top: 5px;
  opacity: 0.8;
  ${CardContainer}:hover & {
    display: block;
    cursor: pointer;
  }
  &:hover {
    opacity: 1.1;
  }
`;

const DeleteButton = styled(Icon)`
  position: relative;
  display: none;
  left: 5px;
  bottom: 15px;
  opacity: 0.5;
  ${CardContainer}:hover & {
    display: flex;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});



// displaying cards inside list
const CollaborationCard = React.memo(({props, chdheader= "", chdname="", chdtext="", header, name, text, configtype, imgID, id, listID, index, dispatch, label, value, projkey, cardcount, optionConfig, project, childCheck, parentID, flagged }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isnewCard, setIsnewCard] = useState(false);
  const classes = useStyles();
  // setting new text
  const [childHeader, setchildHeader] = useState(chdheader);
  const [childName, setchildName] = useState(chdname);
  const [childText, setchildText] = useState(chdtext);
  const [cardHeader, setHeader] = useState(header);
  const [cardName, setName] = useState(name);
  const [cardText, setText] = useState(text);
  const [cardOption, setOption] = useState(configtype);
  // Collapse isOpen State
  const [isOpen, setIsOpen] = React.useState(false);
  const { projectID } = useParams();
  //const project = projects[projectID];
  //console.log(project.cardCounter);
//const key = check.match(/\b(\w)/g).join('').slice(0, 6);
if (isnewCard === true) {
//  console.log('hiii');
}
//console.log(childCheck);
  // checkbox code
  const cardKey = `${projkey}-${cardcount}`;

  // on click close editing
  const closeForm = e => {
    setIsEditing(false);
  };
  const closeCard = e => {
    setIsnewCard(false);
  };
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  // hendling change and setting up new text in card
  const handleheaderChange = e => {
    setHeader(e.target.value);
  };
  const handlenameChange = e => {
    setName(e.target.value);
  };
  const handletextChange = e => {
    setText(e.target.value);
  };
  const handleOptionChange = e => {
    setOption(e.target.value);
  };
  const handlechildheaderChange = e => {
    setchildHeader(e.target.value);
  };
  const handlechildnameChange = e => {
    setchildName(e.target.value);
  };
  const handlechildtextChange = e => {
    setchildText(e.target.value);
  };


  // saving new data
  const saveCard = e => {
    e.preventDefault();
    //console.log(id, listID);
    // dispatch values of card which is editing
    dispatch(editCard(id, listID, cardHeader, cardName, cardText, cardOption));
    setIsEditing(false);
  };
  const addnewCard = e => {
    e.preventDefault();
    const cntr = project.cardCounter++;
    const cardCounter = project.cardCounter;
    const pcntr = project.projectKeyCounter++;
    const projectKeyCounter = project.projectKeyCounter;
    const childCheck = true;
    //console.log(id, listID);
    // dispatch values of card which is editing
    dispatch(addCard(listID, childHeader, childName, childText, cardCounter, projectKeyCounter, cardOption, null, id, childCheck));
    setchildHeader("");
    setchildName("");
    setchildText("");
    setIsnewCard(false);
  };

  const confirmDeleteCard = () => {

      confirmAlert({
        title: 'Confirm Delete',
        message: 'Are you sure to want to delete.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => handleDeleteCard()
          },
          {
            label: 'No',
            onClick: () => closeForm()
          }
        ]
      });
    }

  // deleting card with list ID
  const handleDeleteCard = e => {
    //console.log(listID);
    dispatch(deleteCard(id, listID));
  };


  //console.log(cardText);
//  const renderEditForm = () => {
  //  const checkedItems = checkbox.map((d) => <li key={d.name}>{d.name}</li>);
  if (isEditing === true) {

    return (
      // setting up new text and sending it in CollaborationForm file
      //text={cardText} header={cardHeader} name={cardName}
      <ThumbnailPopup
           content={<>
             <b>Card Details</b>
             <Form style={{ textAlign: "left" }}>
             <div>
             <br/>
             <select className="optionInput" required defaultValue={cardOption} onChange={(e) => handleOptionChange(e)}>{
               // called options from parent page naming optionConfig can trace with same name
               optionConfig.map( (selectedOptn,y) =>
               <option key={y} value={selectedOptn}>{selectedOptn}</option> )
             }</select>

             <label><i style={{ color: "red" }} >*</i>Title</label>
             <StyledCards>
               {/*<Textarea
                 className="styledTextArea"*/}
                 <StyledTextArea
                 value={cardHeader}
                 onChange={e => handleheaderChange(e)}
                 autoFocus
               />
           </StyledCards>
           </div>
           <div>
             <label>Card Description</label>
             <StyledCards>
               <StyledTextArea
                 placeholder="card description"
                 onChange={e => handlenameChange(e)}
                 value={cardName}
               />
             </StyledCards>
           </div>
           <div>
             <label>Details</label>
             <StyledCards>
               <StyledTextArea
                 //placeholder={placeholder}
                 onChange={e => handletextChange(e)}
                 value={cardText}
               />
             </StyledCards>
             </div>

               </Form>
               <br/>
               <Button variant="success" onClick={e => saveCard(e)}>Save</Button>{' '}

             </>}
             closeForm={closeForm}
           />
    );
  }

    else if (isnewCard === true) {
      return (
        <PopupCreateCard
          content={<>
            <b>Card Details</b>
            <Form style={{ textAlign: "left" }}>
            <div>
            <br/>
            <select className="optionInput" required onChange={(e) => handleOptionChange(e)}>{
              // called options from parent page naming optionConfig can trace with same name
              optionConfig.map( (selectedOptn,y) =>
              <option key={y} value={selectedOptn}>{selectedOptn}</option> )
            }</select>
            <label><i style={{ color: "red" }} >*</i>Title</label>
            <StyledCards>
              {/*<Textarea
              className="styledTextArea"*/}
              <StyledTextArea
              chdheader={childHeader}
              onChange={e => handlechildheaderChange(e)}
              autoFocus
              />
            </StyledCards>
            </div>
            <div>
              <label>Card Description</label>
              <StyledCards>
              <StyledTextArea
                placeholder="card description"
                chdname={childName}
                onChange={e => handlechildnameChange(e)}
              />
              </StyledCards>
              </div>
              <div>
                <label>Details</label>
                <StyledCards>
                  <StyledTextArea
                    //placeholder={placeholder}
                    chdtext={childText}
                    onChange={e => handlechildtextChange(e)}
                  />
                </StyledCards>
              </div>
            </Form>
            <br/>
            <Button variant="success" onClick={e => addnewCard(e)}>Add</Button>{' '}

          </>}
               closeCard={closeCard}
             />

        //closeForm={closeForm}

      );
    }

//  };

  // displays card text
  const renderCard = () => {

    if (isOpen === true) {
      if (childCheck === 'true' || childCheck === true) {
        return (
              <PopupCard
               content={<>
                 {/*<b>Card Details</b> */}
                 <Form style={{ textAlign: "left" }} onClick={() => setIsEditing(true)}>
                 <div>
                 <label>Title</label>
               <Typography variant="h5" component="p">
                 {header}
               </Typography>
               </div>
               <div>
                 <label>Card Description</label>
                 <Typography component="p">
                   {name}
                 </Typography>
               </div>
               <div>
               <Typography component="p">
                 Details<br/>
                 {text}
               </Typography>
                 </div>
                 <br/>

                   </Form>
                   <label style={{ color: "green" }}>{configtype}</label>{' '}
                   <label>{cardKey}</label>
                   <br/>
                   <br/>
                   <div style={{ textAlign: "right" }}>
                   <ToggleButtonDefault id={id} parentID={parentID} childCheck={childCheck} projectID={projectID} flagged={flagged}>
                    <div style={{ textAlign: "left", marginBottom: "-30px" }}>
                     <ToggleButton />
                    </div>
                   </ToggleButtonDefault>
                   </div>

                   <br/>

                 {/*<Button variant="success">Addd</Button>{' '}
  1
                 <button className="saveButton" onClick={handleSubmit}>ADD PROJECT</button>*/}
               </>}
               handleClose={togglePopup}
             />
        );

      }
      else {
        return (
              <PopupCard
               content={<>
                 {/*<b>Card Details</b> */}
                 <Form  onClick={() => setIsEditing(true)}>
                 <div style={{ textAlign: "left" }}>
                 <label>Title</label>
               <Typography variant="h5" component="p">
                 {header}
               </Typography>
               </div>
               <div>
                 <label>Card Description</label>
                 <Typography component="p">
                   {name}
                 </Typography>
               </div>
               <div>
               <Typography component="p">
                 Details<br/>
                 {text}
               </Typography>
                 </div>
                 <br/>

                   </Form>
                   <label style={{ color: "green" }}>{configtype}</label>{' '}
                   <label>{cardKey}</label>
                   <br/>
                   <br/>
                   <OpenFormButton onClick={() => setIsnewCard(true)}>
                   <div style={{ marginTop: "-15px", marginRight: "90px" }}>
                     <AddIcon />
                     </div>
                     <div style={{ marginTop: "-25px", marginLeft: "35px" }}>
                     Add new Card
                     </div>
                   </OpenFormButton>
                   <br/>
                   <div style={{ textAlign: "right" }}>
                   <ToggleButtonDefault id={id} parentID={parentID} childCheck={childCheck} projectID={projectID} flagged={flagged}>
                    <div style={{ textAlign: "left", marginBottom: "-30px" }}>
                     <ToggleButton />
                    </div>
                   </ToggleButtonDefault>
                   </div>

                   <br/>

                 {/*<Button variant="success">Addd</Button>{' '}
  1
                 <button className="saveButton" onClick={handleSubmit}>ADD PROJECT</button>*/}
               </>}
               handleClose={togglePopup}
             />
        );

      }
    }
    else {

    return (
      // handling drag and drop
      <Draggable draggableId={String(id)} index={index}>
        {provided => (
          <CardContainer
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            //onDoubleClick={() => setIsEditing(true)}
            onClick={() => { setIsOpen(!isOpen) }}
          >
            {/* buttons for actions
            <Link
              to={`/${id}/Project/`}
              style={{ textDecoration: 'none', marginTop: '4px' }}
            >*/}
            <Card className="thumbnail" className={classes.root} variant="outlined" style={{ outline:"solid", outlineWidth: "thin"}}>

            <EditButton
              onMouseDown={() => setIsEditing(true)}
              fontSize="extrasmall"
            >
              <EditIcon />
            </EditButton>{' '}

              <DeleteButton fontSize="extrasmall" onMouseDown={confirmDeleteCard}>
                <DeleteIcon />
              </DeleteButton>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Description<br/>
                  {name}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  {/*/Type of card or something else*/}
                </Typography>
                <Typography color="textPrimary" component="p">
                <img className="imgSize" src={imgID} />
                  {/*Configuration Type<br/>*/}
                  {configtype}
                </Typography>
                <Typography color="textPrimary" component="p" style={{ textAlign: "right" }}>
                {/*Card Key<br/>*/}
                {cardKey}
                </Typography>
                <ToggleButtonDisplay display="none" flagged={flagged} >
                  <ToggleButton/>
                </ToggleButtonDisplay>

                {/*<strong>May we contact you?</strong>*/}

              </CardContent>
              </Card>
            {/*  </Link>

             Editing card */}

          </CardContainer>
        )}
      </Draggable>

    );
  }
  };

  return /*isEditing ? renderEditForm() :*/ renderCard();
  const mapStateToProps = state => ({
    projects: state.projects
  });

});

export default connect()(CollaborationCard);
