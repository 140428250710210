import React from "react";
import styles from "../org.project.scss";
import Button from 'react-bootstrap/Button';

const Popup = props => {
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}>x</span>
        {props.content}
        <Button variant="danger" onClick={props.handleClose}>Cancel</Button>{' '}
        {/*<button className="cancelButton" onClick={props.handleClose}>Cancel</button>*/}
      </div>
    </div>
  );
};

export default Popup;
