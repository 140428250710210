import { CONSTANTS } from "../actions";
import axios from 'axios';

const initialState = {

};

// handling card add update and delete and saving data
const cardConfigReducer = (state = initialState, action) => {

  switch (action.type) {
    case CONSTANTS.ADD_CARDCONFIG: {
      const { configValue, listID, id, projectID } = action.payload;
      const newCard = {
        configValue,
        _id: `cardConfiguration-${id}`
      };
      const newCardAdd = {
        configValue,
        _id: `cardConfiguration-${id}`
      };
      // inserting data into DB
  axios.post('http://localhost:4000/cardConfig', newCardAdd)
    .then((res) => {
      console.log('Card data successfully inserted');
    });
    console.log(newCardAdd);
    // Done insertion

  return { ...state };
}
// from here no changes are made to edit card configuration. Will add when required
    case CONSTANTS.EDIT_CARDCONFIG: {
      const { id, newHeader, newName, newText } = action.payload;
      const card = state[id];
      card.header = newHeader;
      card.name = newName;
      card.text = newText;

      const editCard = {
        header: newHeader,
        name: newName,
        text: newText
        //id: state[id]
        //list: listID
      };
      //console.log(id);
      // updating data into DB
      axios.put('http://localhost:4000/cardConfig/editcard/'+id, editCard)
        .then((res) => {
          console.log('Card text Updated');
        });
      return { ...state, [`card-${id}`]: card };
    }

    case CONSTANTS.DELETE_CARDCONFIG: {
      const { id, listID } = action.payload;
      const newState = state;
      delete newState[id];

      axios.delete('http://localhost:4000/cardConfig/delete-card/'+id )
        .then((res) => {
          console.log('Card data Deleted successfully');
      });
      const delCard = [id];
      axios.put('http://localhost:4000/lists/pullcardid/'+listID, delCard)
          .then((res) => {
            console.log('Card ID successfully Removed');
          });

      //console.log(listID);
      return newState;
    }
    case CONSTANTS.FETCH_CARDCONFIG: {
      const { newID, val, valed }  = action.payload;
      //console.log(val);
        //const newVal = { value: val, label: val };

        //console.log(newVal);
      return { ...state,  [newID]: {value: val, label: val, id: newID} };
      //return { ...state, val };
    }

//    console.log(newState);
    default:
      return state;
  }
};

export default cardConfigReducer;
