import { CONSTANTS } from "../actions";
import axios from 'axios';

const initialState = {

  "card-0": {
    header: "Card Header",
    name: "Card Name",
    text: "First task",
    id: `card-0`,
    list: "list-0"
  }
};
/*const cardInsert = {
    text: "First task",
    _id: `card-0`,
    list: "list-0"
}
axios.post('http://localhost:4000/cards', cardInsert)
  .then((res) => {
    console.log('Card data successfully inserted');
  });*/

// handling card add update and delete and saving data
const cardsReducer = (state = initialState, action) => {

  switch (action.type) {
    case CONSTANTS.ADD_CARD: {
      const { header, name, text, listID, id, cardCountID, projectKeyCounter, inputOption, imgLocation, parentID, childCheck, flagged } = action.payload;
      const newCard = {
        header,
        name,
        text,
        id: `card-${id}`,
        list: listID,
        cardsID: cardCountID,
        projectKeyCounter: projectKeyCounter,
        configType: inputOption,
        imgID: imgLocation,
        parentID: parentID,
        childCheck: childCheck,
        flagged: flagged
      };
      const newCardAdd = {
        header,
        name,
        text,
        _id: `card-${id}`,
        list: listID,
        cardsID: cardCountID,
        projectKeyCounter: projectKeyCounter,
        configType: inputOption,
        imgID: imgLocation,
        parentID: parentID,
        childCheck: childCheck,
        flagged: flagged
      };
      // inserting data into DB
  axios.post('http://localhost:4000/cards', newCardAdd)
    .then((res) => {
      console.log('Card data successfully inserted');
    });
    //console.log(newCard);
    // Done insertion

  return { ...state, [`card-${id}`]: newCard };
}
    case CONSTANTS.EDIT_CARD: {
      const { id, newHeader, newName, newText, newOption } = action.payload;
      const card = state[id];
      card.header = newHeader;
      card.name = newName;
      card.text = newText;
      card.configType = newOption;

      const editCard = {
        header: newHeader,
        name: newName,
        text: newText,
        configType: newOption

        //id: state[id]
        //list: listID
      };
      //console.log(id);
      // updating data into DB
      axios.put('http://localhost:4000/cards/editcard/'+id, editCard)
        .then((res) => {
          console.log('Card text Updated');
        });
      return { ...state, [`card-${id}`]: card };
    }


    case CONSTANTS.DELETE_CARD: {
      const { id, listID } = action.payload;
      const newState = state;
      delete newState[id];
      console.log(newState[id], newState);


      axios.delete('http://localhost:4000/cards/delete-card/'+id )
        .then((res) => {
          console.log('Card data Deleted successfully');
      });
      const delCard = [id];
      axios.put('http://localhost:4000/lists/pullcardid/'+listID, delCard)
          .then((res) => {
            console.log('Card ID successfully Removed');
          });

      //console.log(listID);
      const cards = state;
      //const lists = board.lists;
      //const newCards = cards.filter(ids => ids !== id);
      //board.lists = newLists;
      //console.log(newCards, state.id);
      //return { ...state, [boardID]: board };

      //return { ...state, items: state.items.filter(i => i.id !== action.payload.id };
      return newState;
    }
    case CONSTANTS.EDIT_CARDIMPEDIMENT: {
      const { projectID, parentID, flagged, id }  = action.payload;
      //const newFlagged = flagged;
      const card = state[id];
      card.flagged = flagged;

      const editCard = {
        flagged: flagged
      };
      //console.log(id);
      // updating data into DB
      axios.put('http://localhost:4000/cards/editcard/'+id, editCard)
        .then((res) => {
          console.log('Flagged Status Updated');
        });
      return { ...state, [`card-${id}`]: card };

      //return { ...state, [newID]: newCard };
    }

    case CONSTANTS.FETCH_CARD: {
      const { newID, newCard }  = action.payload;
      return { ...state, [newID]: newCard };
    }



//    console.log(newState);
    default:
      return state;
  }
};

export default cardsReducer;
