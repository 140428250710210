import { CONSTANTS } from "../actions";


const initialState = ["organization-0"];

// setting up order of new boards
const organizationOrderReducer = (state = initialState, action) => {
  //console.log(action.type);
  switch (action.type) {
    case CONSTANTS.ADD_ORGANIZATION: {
      return [...state, `organization-${action.payload.id}`];
    }
    case CONSTANTS.FETCH_ORGANIZATION: {
      const { newID } = action.payload;
      function searchNewID(keyValue, myArray){
        for (var i=0; i < myArray.length; i++) {
          if (myArray[i] === keyValue) {
            return myArray[i];
            //return false;
          }
        }
      }
      if(!searchNewID(newID, state)){
        return [...state, `${action.payload.newID}`];
      };

    }
    case CONSTANTS.DELETE_ORGANIZATION:
    {
    const {organizationID} = action.payload;
    //console.log(action.payload);
      //const newState = state.filter(({id}) !== action.payload.id);

      const newState = state.filter((organization) => organization.id !== action.payload.newID);
      //console.log(action.payload);
      //console.log(newState);
      return [...newState];
    }
    console.log(state);
  default:
  return state;
  }
};

export default organizationOrderReducer;
