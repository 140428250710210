import React, { useState } from "react";
import { editcardBlock, addCard } from "../actions";
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';

const ToggleButtonDefault = ({ children, dispatch, id, parentID, childCheck, projectID, flagged}) => {

  // React state to manage visibility
  const [show, setShow] = useState(flagged);

  //if (childCheck === true || childCheck === 'true') {
  //  console.log('Child check is true', id, parentID);
  //} else {
  //  console.log('false child', id);

  //}
  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
    const neu = !show;
    if (parentID !== undefined) {
      const flagged = !show;
      dispatch(editcardBlock(projectID, flagged, id, parentID));
    } else {
      const flagged = !show;
      dispatch(editcardBlock(projectID, flagged, id));
    }

  }
  function handleAddCard() {
    setShow(!show);
    const neu = !show;
    if (parentID !== undefined) {
      const flagged = !show;
      dispatch(editcardBlock(projectID, flagged, id, parentID));
    } else {
      const flagged = !show;
      dispatch(editcardBlock(projectID, flagged, id));
    }

  }
  var buttonText = show ? "Remove Flag" : "Add Flag";

  return (
    <div className="component-container">
      {show && children}
      <Button variant="danger" onClick={show ? toggleShow : handleAddCard}>{buttonText}</Button>
    </div>
  );
};

export default connect()(ToggleButtonDefault);
