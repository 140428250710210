import React, { Component } from "react";
//import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser, registerUser } from "./actions/authActions";
import { Provider } from "react-redux";
//import Store from "./stores";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import RegisterP from "./components/auth/RegisterP";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import MPage from "./components/layout/Landing";
import CollaborationBoard from "./components/CollaborationBoard";
import CollaborationOrganization from "./components/CollaborationOrganization";
import CardConfiguration from "./components/CardConfiguration";
import SoftDeleted from "./components/SoftDeleted";
import Home from "./components/Home";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
//import { socket, SocketContext } from "./context/socket";

//import { peer, PeerContext } from "./context/peerC";
//const { persistor, store } = Store();

//import WebSocketProvider, { WebSocketContext } from './WebSocket/websocket.js';
// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
// Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}
function App(){
//class App extends Component {
  //render() {
    return (
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className="App">

            <Routes>
            <Route exact path="/" element={<MPage/>} />
            <Route path="/Register" element={<Register registerUser= {''}/>} />
            <Route path="/RegisterP" element={<RegisterP />} />
            <Route path="/Login" element={<Login />} />
{ /*           <Route path="/:organizationID"  element={<CollaborationOrganization />} />
            <Route path="/:Organization/SoftDeleted"  element={<SoftDeleted />} />
            <Route path="/:projectID/Project//*"  element={<CollaborationBoard />} />
            <Route path="/:projectID/CardConfiguration//*"  element={<CardConfiguration />} />
*/}            <Route path="/dashboard" element={
              <PrivateRoute>
                  <Dashboard />
              </PrivateRoute>
            } />
            <Route path="/:organizationID" element={
              <PrivateRoute>
                  <CollaborationOrganization />
              </PrivateRoute>
            } />
            <Route path="/:Organization/SoftDeleted" element={
              <PrivateRoute>
                  <SoftDeleted />
              </PrivateRoute>
            } />
            <Route path="/:projectID/Project//*" element={
              <PrivateRoute>
                  <CollaborationBoard />
              </PrivateRoute>
            } />
            <Route path="/:projectID/CardConfiguration//*" element={
              <PrivateRoute>
                  <CardConfiguration />
              </PrivateRoute>
            } />
            </Routes>
           </div>
        </Router>
        </PersistGate>
      </Provider>
    );
  //}
}

export default App;
