import React from "react";
import PropTypes from "prop-types";
import {logoutUser} from "../../actions/authActions.js";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { connect } from "react-redux";
import Home from "../Home.js";

function Dashboard({ auth, logoutUser }) {
  const navigate = useNavigate(); // Access navigate from useNavigate

  const onLogoutClick = () => {
    logoutUser();
    navigate('/'); // Use navigate to redirect
  };

  const { user } = auth;

  return (
    <div style={{ height: "75vh" }} className="container valign-wrapper">
      <div className="row">
        <div className="col s12 center-align">
          <h4>
            <b>Hey there,</b> {user.username}
            <p className="flow-text grey-text text-darken-1">
            </p>
          </h4>
          <Home />
          <button
            style={{
              width: "150px",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              marginTop: "1rem"
            }}
            onClick={onLogoutClick}
            className="btn btn-large waves-effect waves-light hoverable blue accent-3"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Dashboard);
