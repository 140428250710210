import { combineReducers } from "redux";
import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import organizationsReducer from "./organizationsReducer";
import activeOrganizationReducer from "./activeOrganizationReducer";
import organizationOrderReducer from "./organizationOrderReducer";
import projectsReducer from "./projectsReducer";
import listsReducer from "./listsReducer";
import cardsReducer from "./cardsReducer";
import cardConfigReducer from "./cardConfigReducer";
import boardsReducer from "./boardsReducer";
import boardOrderReducer from "./boardOrderReducer";
import activeBoardReducer from "./activeBoardReducer";
import activeProjectReducer from "./activeProjectReducer";
import orgcountersReducer from "./orgcountersReducer";
export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    organizations: organizationsReducer,
    activeOrganization: activeOrganizationReducer,
    organizationOrder: organizationOrderReducer,
    projects: projectsReducer,
    lists: listsReducer,
    cards: cardsReducer,
    cardConfig: cardConfigReducer,
    boards: boardsReducer,
    boardOrder: boardOrderReducer,
    activeBoard: activeBoardReducer,
    activeProject: activeProjectReducer,
    orgcounters: orgcountersReducer
})
