import { CONSTANTS } from "../actions";
//import { SET_ACTIVE_ORGANIZATION } from '../actions/types'


const initialState ={
    isAuthenticated: false,
    errors:{},
    user: {}
   // loading:false
};

// storing data of active organizations
const activeOrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_ACTIVE_ORGANIZATION: {
      return action.payload;
    }
    default:
      return state;
  }
  switch (action.type) {
    case CONSTANTS.ADD_ORGANIZATION: {
      return action.payload;
    }
    //console.log(state);
    default:
      return state;
  }
  switch (action.type) {
    case CONSTANTS.DELETE_ORGANIZATION: {
      return action.payload;
    }
    //console.log(state);
    default:
      return state;
  }
};

export default activeOrganizationReducer;
