export * from "./listsActions";
export * from "./projectsActions";
export * from "./cardsActions";
export * from "./cardConfigActions";
export * from "./boardActions";
export * from "./organizationActions";

export const CONSTANTS = {
  ADD_ORGANIZATION: "ADD_ORGANIZATION",
  ADD_BOARD: "ADD_BOARD",
  ADD_PROJECT: "ADD_PROJECT",
  ADD_LIST: "ADD_LIST",
  ADD_CARD: "ADD_CARD",
  ADD_CARDCONFIG: "ADD_CARDCONFIG",
  ADD_ORGCOUNTER: "ADD_ORGCOUNTER",
  DRAG_HAPPENED: "DRAG_HAPPENED",
  EDIT_ORGANIZATION: "EDIT_ORGANIZATION",
  EDIT_PROJECT_TITLE: "EDIT_PROJECT_TITLE",
  EDIT_PROJECT: "EDIT_PROJECT",
  EDIT_LIST_TITLE: "EDIT_LIST_TITLE",
  EDIT_CARD: "EDIT_CARD",
  EDIT_CARDCONFIG: "EDIT_CARDCONFIG",
  EDIT_ORGCOUNTER: "EDIT_ORGCOUNTER",
  EDIT_CARDIMPEDIMENT: "EDIT_CARDIMPEDIMENT",
  DELETE_ORGANIZATION: "DELETE_ORGANIZATION",
  SOFT_DELETE_ORGANIZATION: "SOFT_DELETE_ORGANIZATION",
  RESTORE_ORGANIZATION: "RESTORE_ORGANIZATION",
  DELETE_PROJECT: "DELETE_PROJECT",
  DELETE_LIST: "DELETE_LIST",
  DELETE_CARD: "DELETE_CARD",
  SET_ACTIVE_ORGANIZATION: "SET_ACTIVE_ORGANIZATION",
  SET_ACTIVE_PROJECT: "SET_ACTIVE_PROJECT",
  SET_ACTIVE_BOARD: "SET_ACTIVE_BOARD",
  FETCH_ORGANIZATION: "FETCH_ORGANIZATION",
  FETCH_BOARD: "FETCH_BOARD",
  FETCH_LIST: "FETCH_LIST",
  FETCH_PROJECT: "FETCH_PROJECT",
  FETCH_CARD: "FETCH_CARD",
  FETCH_CARDCONFIG: "FETCH_CARDCONFIG",
  FETCH_ORGCOUNTER: "FETCH_ORGCOUNTER"

};
