import React, { useState } from "react";
import CollaborationCard from "./CollaborationCard";
import CollaborationCreate from "./CollaborationCreate";
import { Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { editTitle, deleteList } from "../actions";
import { Input } from 'reactstrap';
import Icon from "@material-ui/core/Icon";
import DeleteIcon from '@material-ui/icons/Delete';

/*const ListContainer = styled.div`
  background-color: #dfe3e6;
  border-radius: 3px;
  width: 300px;
  padding: 8px;
  height: 100%;
  margin: 0 8px 0 0;
`;

const StyledInput = styled.input`
  width: 100%;
  border: none;
  outline-color: blue;
  border-radius: 3px;
  margin-bottom: 3px;
  padding: 5px;
`;
*/
const TitleContainer = styled.div`
  width: 100%;
  background: #9e9d9d;
  height: 100%;
  display: flex;
  margin-bottom: 15px;
  margin-top: -15px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const DeleteButton = styled(Icon)`
  position: relative;
  display: none;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.8;
  ${TitleContainer}:hover & {
    display: flex;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const ListTitle = styled.h4`
  transition: background 0.3s ease-in;
  ${TitleContainer}:hover & {
    color: white;
  }
`;

  // getting data from other files to display it in form of lists and cards
  const CollaborationList = ({ title, optionValues, cards, listID, project, index, dispatch, isDropDisabled }) => {
  // handle edit
  const [isEditing, setIsEditing] = useState(false);
  // passing list title
  const [listTitle, setListTitle] = useState(title);
  //console.log(project.projectKey);
  //const key = project.projectKey;
  //const counter = cards.projectKeyCounter;
  //const cardKey = `${key}-${counter}`;
  const renderEditInput = () => {
    return (
      // edit form
      <form onSubmit={handleFinishEditing}>
        <Input
          className="listInput"
          type="text"
          value={listTitle}
          onChange={handleChange}
          autoFocus
          onFocus={handleFocus}
          onBlur={handleFinishEditing}
        />
      </form>
    );
  };

  // show edit delete buttons on hover
  const handleFocus = e => {
    e.target.select();
  };

  // change text handling
  const handleChange = e => {
    e.preventDefault();
    setListTitle(e.target.value);
  };

  // final data at the end of editing
  const handleFinishEditing = e => {
    setIsEditing(false);
    dispatch(editTitle(listID, listTitle));
  };

  // on click close editing
  const closeForm = e => {
    setIsEditing(false);
  };
  const confirmDeleteList = () => {

      confirmAlert({
        title: 'Confirm Delete',
        message: 'Are you sure to want to delete.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => handleDeleteList()
          },
          {
            label: 'No',
            onClick: () => closeForm()
          }
        ]
      });
    }

  // deleting list on delete button click
  const handleDeleteList = () => {
    dispatch(deleteList(listID));
  };

  return (
    // drag and drop functions with changing IDs
    <Draggable draggableId={String(listID)} index={index}>
      {provided => (
        <div className="listContainer2"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Droppable droppableId={String(listID)} isDropDisabled={isDropDisabled} type="card">
            {provided => (
              <div>
                <div>
                  {isEditing ? (
                    renderEditInput()
                  ) : (
                    // edit/delete button and display of list
                    <TitleContainer onClick={() => setIsEditing(true)}>
                      <ListTitle>{listTitle}</ListTitle>
                      <DeleteButton onClick={confirmDeleteList} fontSize="extrasmall">
                        <DeleteIcon />
                      </DeleteButton>
                    </TitleContainer>
                  )}
                </div>
                {/* display of card*/}
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {cards.map((card, index) => (
                    <CollaborationCard
                    key={card.id}
                    text={card.text}
                    header={card.header}
                    name={card.name}
                    id={card.id}
                    projkey={project.projectKey}
                    cardcount={card.projectKeyCounter}
                    configtype={card.configType}
                    imgID={card.imgID}
                    parentID={card.parentID}
                    childCheck={card.childCheck}
                    index={index}
                    listID={listID}
                    optionConfig={project.cardConfigurations}
                    flagged={card.flagged}
                    project={project}

                  />
                  ))}
                  {provided.placeholder}
                  <CollaborationCreate optionValues={optionValues} listID={listID} project={project} />
                </div>
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

export default connect()(CollaborationList);
