import React, { useEffect } from "react";
import CollaborationList from "./CollaborationList";
import { connect } from "react-redux";
import CollaborationCreate from "./CollaborationCreate";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
//import styled from "styled-components";
import { sort, setActiveProject } from "../actions";
import { useNavigate, useParams } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import styles from "../org.project.scss";
import { Dropdown, Selection } from 'react-dropdown-now';
import 'react-dropdown-now/style.css';
//import { withRouter } from "react-router";
/*/ styling
const ListsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;*/

// TODO: Fix performance issue

const CollaborationBoard= ({ projects, lists, cards, cardConfig, match, index, dispatch }) => {
  // Collapse isOpen State
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate;
  const { projectID } = useParams();
  const project = projects[projectID];
  const optionValues = Object.values(cardConfig);
  const [isDropDisabled, setIsDropDisabled] = React.useState(false);
  //console.log(project.lists['0']);
  const testereee = 'list-6f7c4cc9-6450-48b6-ae23-66608d61db96';
  let optList = project.lists
  const blockedIDs = project.dependentID;
  //const check = blockedIDs.find((configg) => configg === testereee)
  console.log(lists, cards, projects);
  const options = [
    { value:  '/card/', label: 'Edit Card Type' },
    { value:  '/status/', label: 'Edit Status'  },
    { value:  '/project/', label: 'Edit Project' }
      //navigate(`/${newID}/CardConfiguration/`);
  ];


  const orID = project.organization;
  useEffect(() => {
    dispatch(setActiveProject(projectID))
  }, []);
  /*const goBack = () => {
    goBack();

  }*/
  // action on drag start
  //block drag disabled drag locked drag
  const onDragStart = e => {
    const {draggableId} = e;
    console.log(draggableId);
    const check = blockedIDs.find((configg) => configg === draggableId)
    console.log(check);

    if (draggableId === check) {
        setIsDropDisabled(true)
        //console.log('true', draggableId);
        //alert('hi');
    }
    else {
      setIsDropDisabled(false)
      //console.log('false');
    }
  }

  // actions on drag end
  const onDragEnd = result => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }
    // updating IDs of drag and drop
    dispatch(
      sort(
        source.droppableId,
        destination.droppableId,
        source.index,
        destination.index,
        draggableId,
        type
      )
    );
  };

  // getting current data of boards, lists and cards
//  render = () => {
    if (!project) {
      return <p>Project Board not found</p>;
    }
    // ordering lists inside board
    const listOrder = project.lists;

    return (
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div style={{display: 'block', width: "100%"}}>
          <Navbar color="light" light expand="lg">
            <NavbarBrand href={`/#/${orID}`} onClick={() => navigate(-1)} align='left'>{project.title}</NavbarBrand>
            <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink href="/Organization/SoftDeleted/" className='btnadd' align="right">Soft Deleted</NavLink>
              </NavItem>
              {/*<select className="optionInput" required >
               { //option.map( (selectedOptn,y) =>
                //<option key={y} value={selectedOptn}>{selectedOptn}</option> )
              //}
              <option value={'/Organization/SoftDeleted/'}>check</option>
              <option value={'selectedOptn'}>one</option>
              <option  value={'selectedOptn'}>one</option>
              <option  value={'selectedOptn'}>one</option>
              </select>*/}
              <Dropdown
                placeholder="Select an option"
                options={options}
                value="one"
                //onChange={(value) => console.log('change!', value)}
                onChange={(value) => window.location.href=value.value}
                onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                onOpen={() => console.log('open!')}
              />

              </Nav>
            </Collapse>
          </Navbar>
        </div>
        <br/>
        <div className="overflow-scroll" >
        <Droppable droppableId={String(projectID)} isDropDisabled={isDropDisabled} direction="horizontal" type="list">
          {provided => (
            <div
              className="listContainer"
              {...provided.droppableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}>
              {listOrder.map((listID, index) => {
                const list = lists[listID];
                //console.log(listID, index);
                if (list) {
                  const listCards = list.cards.map(cardID => cards[cardID]);
                  return (
                    <CollaborationList
                      optionValues={optionValues}
                      project={project}
                      list={list}
                      listID={list.id}
                      key={list.id}
                      title={list.title}
                      cards={listCards}
                      index={index}
                      isDropDisabled={isDropDisabled}
                    />
                  );
                }
              })}
              {provided.placeholder}
              <CollaborationCreate optionValues={optionValues} list project={project}/>
            </div>
          )}
        </Droppable>
      </div>
      </DragDropContext>
    );
//  }
}
// setting up state of elements
const mapStateToProps = state => ({
  projects: state.projects,
  lists: state.lists,
  cards: state.cards,
  boards: state.boards,
  cardConfig: state.cardConfig

});

export default connect(mapStateToProps)(CollaborationBoard);
