import React from 'react';
import styles from '../../mystyle.module.scss';
/*import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AiOutlineEye ,AiOutlineEyeInvisible} from "react-icons/ai";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const eye = < AiOutlineEye />;
const ineye =<AiOutlineEyeInvisible/>;*/

class PasswordShowHide extends React.Component {
      constructor(props) {
        super(props)

        this.state = {
            hide: true,
            
        }
    }

    hideSwitch = ev => {
        this.setState({ hide: !this.state.hide })
    }

    handleInputChange=(e)=>{
      this.setState({
        [e.target.name]: e.target.value
      });
    }
    
    render() {
      const { password , onChangeValue } = this.props;
        const { hide } = this.state
        return (
            <><div>
          {/*<FormControl >
          
          <Input
            id = "password"
            name = "password"
            className = {styles.inM}
            type = {hide ? 'password':'text'}
            value={password} onChange={onChangeValue}
            
            endAdornment={
              <InputAdornment position = "end">
                <IconButton
                  
                  onClick={this.hideSwitch}
                  edge="start"
                >
                {hide ? <VisibilityOff fontSize="medium" /> : <Visibility fontSize="medium"/>}
                </IconButton>
              </InputAdornment>
            }
            
          />
          </FormControl>*/}
                    <label className="styles.lblM">
                    <input className={styles.inM} type={hide ? 'password' : 'input'}
                    value={password} onChange={onChangeValue} /></label>
                    <div >
                        {
                        hide ?
                             <label><input className="styles.inM" type="checkbox" id="pshow" name="pshow" value="Show Password"  onClick={this.hideSwitch}/> <span className="styles.lblM" >Show Password</span></label> :
                             <label><input type="checkbox" id="phide" name="phide" value="Hide Password"  onClick={this.hideSwitch}/>  <span className="styles.clbl">Hide Password</span></label> 
                        }
                            {/*<i>{eye}</i> :
                        <i>{ineye}</i>}*/}
                        </div>
                
                
                    </div>
                    {/*
                    <label className="password">
                    <input className={styles.inMP} type={hide ? 'password' : 'input'} />
                    <span className="password__show" onClick={this.hideSwitch}>
                        {hide ?
                            <i className="styles.ic">{eye}</i> :
                            <i className="styles.ic">{ineye}</i>}</span>
                </label>
                */}
                </>
            
        )
    }
}

export default PasswordShowHide;
//ReactDOM.render(<PasswordShowHide/>, document.getElementById('react'));
/*class PasswordShowHide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      password: '',
      errors:{}
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }
  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }
  render() {
    const {errors} = this.state;
    return (
        
      <><div className="pass-wrapper">
          
            <input type={this.state.hidden ? 'password' : 'text'} value={this.state.password}
                className={classnames(styles.inMP, { invalid: errors.password || errors.passwordincorrect })}
                name="password" onChange={this.handlePasswordChange} />
                <i  className="styles.ic" onClick={this.toggleShow}>{eye}</i>
        </div>
        </>
    );
  }
}

export default PasswordShowHide;

/*import React, { useState } from "react";
import { useForm } from "react-hook-form";
      
      <input
          type={this.state.hidden ? 'password' : 'text'}
          value={this.state.password}
          onChange={this.handlePasswordChange}
          
     />
//import FontAwesomeIcon from 'react-fontawesome'
import { AiOutlineEye } from "react-icons/ai";
//import { faEye } from "fortawesome/free-solid-svg-icons";
const eye = < AiOutlineEye />;

export default function PasswordShowHide() {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const {handleSubmit } = useForm();
  const onSubmit = data => {
    console.log(data);
  };

  return (
      <>
    <div>
      <div>
        {" "}
        <input
          placeholder="Password"
          name="password"
          type={passwordShown ? "text" : "password"}
        />
        <i onClick={togglePasswordVisiblity}>{eye}</i>{" "}
      </div>
      <button type="submit" onClick={handleSubmit(onSubmit)}>
        Submit
      </button>
    </div>
    </>
  );
}*/