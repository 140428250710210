import React, {useState, useEffect} from 'react';
import styles from '../../mystyle.module.scss';
//import Register from "../auth/Register.js"
import Login from "../auth/Login.js"
import {Provider} from "react-redux";
import {persistor, store} from "../../store";
import { useNavigate } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}
//class MPage extends React.Component{
  function  MPage(props) {
      const [clicked, setClicked]  = useState({clicked: false});
    //  const [submitted, setSubmitted] = useState('');
      const [Errors, setErrors] = useState('');
      let navigate = useNavigate();

      const handleClick = (e) =>{
        e.preventDefault();
        setErrors('');
        setClicked(true);

        navigate('/Register');
      // console.log('handleclk',props.clicked);
      }
    //const { showSignUpF } = this.state;

    return(
      <>
        <ErrorBoundary>
        <Provider store = {store}>

        <div id="SignUpSignIn">
          <div>
              <h1 className={styles.head1}> Agile Online Collaboration Tool </h1>
              <button  className={styles.btnM} onClick={()=> navigate('/Register')}> Sign Up </button>
          <Login />
          </div>
        </div>

       </Provider>
       </ErrorBoundary>
       </>
    );
  }

export default MPage;
