import { CONSTANTS } from "../actions";

const initialState = null;

// setting up order of new boards
const boardOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.ADD_BOARD: {
      return [...state, `board-${action.payload.id}`];
    }
    case CONSTANTS.FETCH_BOARD: {
      const { newID } = action.payload;
      function searchNewID(keyValue, myArray){
        for (var i=0; i < myArray.length; i++) {
          if (myArray[i] === keyValue) {
            return myArray[i];
            return false;
          }
        }
      }
      if(!searchNewID(newID, state)){
        return [...state, `${action.payload.newID}`];
      };
    }
  default:
  return state;
  }
};

export default boardOrderReducer;
