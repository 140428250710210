import { CONSTANTS } from "../actions";
import axios from 'axios';
import { v4 as uuid } from 'uuid';

const initialState = {
  "organization-0": {
    id: "organization-0",
    organizationsID: "12345",
    projects: ["project-0"],
    title: "First Organization",
    projCounter: "1000"
  }
};
/*const initialOrganization = {
    _id: "organization-0",
    countid: "12345",
    projects: ["project-0"],
    title: "First Organization"
}
axios.post('http://localhost:4000/organizations', initialOrganization)
  .then((res) => {
    console.log('Initial data successfully inserted');
  });*/

// handling organization/list add/delete and drag function
const organizationsReducer = (state = initialState, action) => {
  //console.log(state);
  switch (action.type) {

    case CONSTANTS.ADD_PROJECT: {
      const { organizationID, newID, setProjID } = action.payload;
      const organization = state[organizationID];
      const newProjectID = newID;
      const newProjects = [...organization.projects, newProjectID];
      organization.projects = newProjects;
      const insertProject = [newProjectID];
      //console.log(organizationID);
      axios.put('http://localhost:4000/organizations/edit/'+organizationID, insertProject)
        .then((res) => {
          console.log('Project ID inserted');
        });
        //for redux
        organization.projCounter = setProjID;
        //for database
        const updateCounter = {
          projCounter: setProjID
        };
        //console.log(updateCounter);
        axios.put('http://localhost:4000/organizations/insertCounter/'+organizationID, updateCounter)
          .then((res) => {
            console.log('Counter updated');
          });


      return { ...state, [organizationID]: organization };
    }

    /*case CONSTANTS.DRAG_HAPPENED: {
      const { organizationID } = action.payload;
      const organization = state[organizationID];
      const lists = organization.lists;
      const {
        droppableIndexEnd,
        droppableIndexStart,

        type
      } = action.payload;
      //console.log(action.payload);
      // draggin lists around
      if (type === "list") {
        const pulledOutList = lists.splice(droppableIndexStart, 1);
        lists.splice(droppableIndexEnd, 0, ...pulledOutList);
        organization.lists = lists;

        return { ...state, [organizationID]: organization };
      }
      return state;
    }*/
    case CONSTANTS.DELETE_PROJECT: {
      const { projectID, organizationID } = action.payload;
      const organization = state[organizationID];
      const projects = organization.projects;
      const newProjects = projects.filter(id => id !== projectID);
      organization.projects = newProjects;
      return { ...state, [organizationID]: organization };
    }

    case CONSTANTS.ADD_ORGANIZATION: {
      const { title, id, orgid, orgObjID } = action.payload;
      const newID = `organization-${id}`;
      if (orgObjID === undefined) {
        //console.log(orgid);
        var dat_e = new Date();
        const count = 1001;
        dat_e = dat_e.toISOString().replace(/[^0-9]/g, "").slice(2, 8);
        const newOrgcount = "Org"+dat_e+count;
        const orId = uuid();
        //console.log(newOrgcount);
        const neworId = `${orId}`;
        const counterInsert = {
          _id: neworId,
          orgID: count
        }
        axios.post('http://localhost:4000/orgCounters/', counterInsert)
          .then((res) => {
            console.log('data successfully inserted');
          });
          const projcount = 1000;
          const organizationAdd = {
            _id: newID,
            organizationsID: newOrgcount,
            title,
            projects: [],
            softdelete: false,
            Time: '',
            projCounter: projcount
          };

          axios.post('http://localhost:4000/organizations', organizationAdd)
            .then((res) => {
              console.log('data successfully inserted');
            });

      }
      else {
        var dat_e = new Date();
        dat_e = dat_e.toISOString().replace(/[^0-9]/g, "").slice(2, 8);
        const newOrgcount = "Org"+dat_e+orgid;
        const counterTest = {
          orgID: orgid
        }
        axios.put('http://localhost:4000/orgCounters/edit-orgcounter/'+orgObjID, counterTest)
        .then((res) => {
          console.log('ID incremented');
        });

        const projcount = 1000;

        const organizationAdd = {
          _id: newID,
          organizationsID: newOrgcount,
          title,
          projects: [],
          softdelete: false,
          Time: '',
          projCounter: projcount
        };

          axios.post('http://localhost:4000/organizations', organizationAdd)
            .then((res) => {
              console.log('data successfully inserted');
            });

      }
      var dat_e = new Date();
      dat_e = dat_e.toISOString().replace(/[^0-9]/g, "").slice(2, 8);
      const newOrgcount = "Org"+dat_e+orgid;
      const projcount = 1000;
      const newOrganization = {
        id: newID,
        organizationsID: newOrgcount,
        title,
        projects: [],
        softdelete: false,
        Time: '',
        projCounter: projcount
      };

      return { ...state, [newID]: newOrganization };

    }
    case CONSTANTS.EDIT_ORGANIZATION: {
      const { organizationID, orgTitle } = action.payload;
      const organization = state[organizationID];
      organization.title = orgTitle;
      const editOrganization = {
        title: orgTitle
        //id: state[id]
        //list: listID
      };
      //console.log(id);
      // updating data into DB
      axios.put('http://localhost:4000/organizations/edit-organization/'+organizationID, editOrganization)
        .then((res) => {
          console.log('Organization title Updated');
        });
      return { ...state, [organizationID]: organization };
    }

    case CONSTANTS.DELETE_ORGANIZATION: {
      const { organizationID } = action.payload;
      const newState = state;
      delete newState[organizationID];
      console.log(newState);
      axios.delete('http://localhost:4000/organizations/delete-organization/'+organizationID )
        .then((res) => {
          console.log('Organization data Deleted successfully');
      });

      return newState;
    }
    case CONSTANTS.SOFT_DELETE_ORGANIZATION: {
    const {organizationID} = action.payload;
    const organization = state[organizationID];
    var dat_e = Date().slice(0, 21);
    const softdelOrganization = {
      softdelete: true,
      Time: dat_e
    };
    // updating data into DB
    axios.put('http://localhost:4000/organizations/softdelete-organization/'+organizationID, softdelOrganization)
      .then((res) => {
        console.log('Organization Soft Deleted');
      });
      //, {/*[`organization-${organizationID}`]: organization*/}
      return { ...state, [organizationID]: organization };

    //return { ...state };
    }
    case CONSTANTS.RESTORE_ORGANIZATION: {
    const {organizationID} = action.payload;
    const organization = state[organizationID];
    const restoreOrganization = {
      softdelete: false
    };
    // updating data into DB
    axios.put('http://localhost:4000/organizations/restore-organization/'+organizationID, restoreOrganization)
      .then((res) => {
        console.log('Organization Restored');
      });
      console.log(state);
      //, {/*[`organization-${organizationID}`]: organization*/}
    return { ...state, [organizationID]: organization };
    }


    case CONSTANTS.FETCH_ORGANIZATION: {
      const { newID, newOrganization, projectCounter }  = action.payload;
      //console.log(newID);
      return { ...state, [newID]: newOrganization, projectCounter };
    }
    /*case CONSTANTS.FETCH_LIST: {
      const { newID, newList }  = action.payload;
      console.log(newList);
      return { ...state, [newID]: newList };
    }*/
     default:
      return state;
  }
};


export default organizationsReducer;
