import React from "react";
import styled from "styled-components";

/* React component where show/hide
  functionality is implemented */
export default function Default() {
  return (
    <div>
    <img className="imgSize" src={"../../images/flag.png"} />
    </div>
  );
}
