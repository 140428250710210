import {CLEAR_ERRORS,GET_ERRORS,  SET_INVALID_USER, SET_ALREADY_SENT} from "../actions/types.js";
const initialState = { };

export default function (state = initialState, action) {
    switch (action.type){
        case GET_ERRORS:
            console.log('in red', action.payload);
            return action.payload;

        case SET_INVALID_USER:
            return{
                //...state,
                isAuthenticated: false,
                errors:action.payload
            };
        case SET_ALREADY_SENT:
            console.log('already sent', action.payload);
            return{
                //...state,
                errors: action.payload
            };
        /*case CLEAR_ERRORS:
            console.log('in unset error Error',action.payload);
            return {
                ...state,
                  errors : {}
            }
*/
        default:
            return state;
    }

}
