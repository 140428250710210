import { CONSTANTS } from "../actions";
import { v4 as uuid } from 'uuid';
//import axios from 'axios';

// adding new card
export const addCard = (listID, header, name, text, cardCounter, projectKeyCounter, inputOption, imgLocation, parentID, childCheck) => {
  return (dispatch, getState) => {
    const projectID = getState().activeProject;
    //console.log(cardCounter, projectKeyCounter, listID, childCheck);
    var dat_e = new Date();
    dat_e = dat_e.toISOString().replace(/[^0-9]/g, "").slice(2, 8);
    const cardCountID = "Card"+dat_e+cardCounter;

    const id = uuid();
    dispatch({
      type: CONSTANTS.ADD_CARD,
      payload: { header, name, text, listID, id, cardCountID, cardCounter, projectID, projectKeyCounter, inputOption, imgLocation, parentID, childCheck}
    });
  };
};

// editing existing card
export const editCard = (id, listID, newHeader, newName, newText, newOption) => {
  return {
    type: CONSTANTS.EDIT_CARD,
    payload: { id, listID, newHeader, newName, newText, newOption }
  };
};
export const editcardBlock = (projectID, flagged, id, parentID) => {
  //console.log(projectID, parentID, flagged, id);
  return {
    type: CONSTANTS.EDIT_CARDIMPEDIMENT,
    payload: { projectID, parentID, flagged, id }
  };
};
// deleting card
export const deleteCard = (id, listID) => {
  return {
    type: CONSTANTS.DELETE_CARD,
    payload: { id, listID }
  };
};
