import React, { useState } from "react";
import styled from "styled-components";
import Icon from "@material-ui/core/Icon";
import Textarea from "react-textarea-autosize";
import Card from "@material-ui/core/Card";
import CollaborationCreate from "./CollaborationCreate";
//import CollaborationTest from "./CollaborationTest";

const Container = styled.div`
  width: 284px;
  margin-bottom: 8px;
  margin-top: 80px;
`;

const StyledCard = styled(Card)`
  min-height: 85px;
  padding: 6px 8px 2px;
`;

const StyledOrg = styled(Card)`
  min-height: 50px;
  padding: 6px 8px 2px;
`;

const StyledTextArea = styled(Textarea)`
  resize: none;
  width: 100%;
  overflow: hidden;
  outline: none;
  border: none;
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const StyledIcon = styled(Icon)`
  margin-left: 8px;
  cursor: pointer;
`;


// this form gets data from CollaborationCreate and display it here and add styling
const ProjectForm = React.memo(
  ({props, title = "", onChange,  onTitleChange, closeForm, children, label, orgID, value, id }) => {

    const placeholder = "Enter project title...";
    //"Enter a title for this card..."


      return (
      <Container>
        <div>
          <label>Title</label>
          <StyledOrg>
            <StyledTextArea
              placeholder={title}
              value={title}
              onChange={e => onTitleChange(e)}
              autoFocus
            />
          </StyledOrg>
        </div>
        <br/>
        <ButtonContainer>
          {children}
          <StyledIcon onMouseDown={closeForm}>close</StyledIcon>
        </ButtonContainer>
      </Container>
    );
  }
);

export default ProjectForm;
