import{
    SET_CURRENT_USER,
    USER_UREG,
    SET_CURRENT_USER_LOGOUT,
    GET_ERRORS,
    CLEAR_ERRORS,
    RESEND_CODE,
    SET_ALREADY_SENT
}  from "../actions/types.js";

const isEmpty = require("is-empty");

const initialState ={
    isReg:false,
    isAuthenticated: false,
    errors:{},
    user: {},
    resend:false,
    registerUser:{}
};

export default function (state = initialState, action) {
    switch (action.type){

        case SET_CURRENT_USER:
           //console.log(action.payload, 'curuser');
            return{
                ...state,
                isAuthenticated: true,
                user: action.payload,
                errors:{}
            };

        case USER_UREG:
            console.log('in user_uregreducer',action.payload);
            return{
                ...state,
                isReg:true,
                //isAuthenticated:true,
                user:action.payload,
                errors:{}
            };
        case RESEND_CODE:
            console.log('in RESEND',action.payload);
            return{
                ...state,
                resend:true,
                errors:{}
            };

        case SET_CURRENT_USER_LOGOUT:
               console.log('u logout', action.payload)
            return{
                ...state,
                isReg:false,
                isAuthenticated: false,
                errors: {},
                //user:action.payload,
                user:{}
            };

        case GET_ERRORS:
            //console.log('errors', action.payload);
            return{
                ...state,
                errors:action.payload
            }

        case CLEAR_ERRORS:
            console.log('in unset error auth reducer',action.payload);
            return {
                ...state,
                  errors : {}
            }
        default:
            return state;
    }
}
