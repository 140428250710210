import React, { useState } from "react";
import Icon from "@material-ui/core/Icon";
import AddIcon from '@material-ui/icons/Add';
import CollaborationButton from "./CollaborationButton";
import { connect } from "react-redux";
import { addList, addCard } from "../actions";
import styled from "styled-components";
import CollaborationForm from "./CollaborationForm";
import CollaborationOpenForm from "./CollaborationOpenForm";

//import { setActiveProject } from "../actions";


const CollaborationCreate = React.memo(
  ({props, list, text, header, name, configtype, dispatch, project, listID, optionValues, key }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputHeader, setInputHeader] = useState(header);
    const [inputName, setInputName] = useState(name);
    const [inputText, setInputText] = useState(text);
    const [inputOption, setInputOption] = useState(configtype);
    const buttonText = list ? "Add another status" : "Add new card";
    const buttonTextOpacity = list ? 1 : 0.5;
    const buttonTextColor = list ? "white" : "inherit";
    const buttonTextBackground = list ? "rgba(0,0,0,.35)" : "inherit";
    const OpenFormButton = styled.div`
      display: flex;
      align-items: left;
      cursor: pointer;
      border-radius: 3px;
      height: 36px;
      margin-left: 8px;
      width: 300px;
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 10px;
      color: ${buttonTextColor};
      background-color: ${buttonTextBackground};
    `;
    //const finder = project.cardConfigurations;
    //let index = finder.indexOf("one");

    //const check = optionValues.find((configg) => configg.value === 'Epic')
    //console.log( index);
    // on click close editing
    const closeForm = e => {
      setIsEditing(false);
    };

    // changing text inside form
    const handleheaderInputChange = e => {
      setInputHeader(e.target.value);
    };
    const handlenameInputChange = e => {
      setInputName(e.target.value);
    };
    const handletextInputChange = e => {
      setInputText(e.target.value);
    };
    const handleOptionInputChange = e => {
      setInputOption(e.target.value);
    };

    // Adding submit function axios
    /*constructor(props) {
      super(props)
      this.state = {
        //boards: []
        organizations: []
      };
    }*/

    // saving new data
    const handleSubmit = e => {
      e.preventDefault();
    };

      // handling new lists and text
    const  handleAddList = e => {
      //console.log(project);
      setIsEditing(false);
      const cntr = project.listCounter++;
      const listCounter = project.listCounter;
      //console.log(project.listCounter, listCounter);
      //setting up text
        dispatch(addList(inputText, listCounter));
        setInputText("");

  //    return;
    };

    // adding new card
    const handleAddCard = e => {
      e.preventDefault();
      //console.log(project.cardCounter);
      if (inputHeader === "" || inputHeader === " ") {
        alert("Please enter Title to proceed");
      }else {
        setIsEditing(false);
        const cntr = project.cardCounter++;
        const cardCounter = project.cardCounter;
        const pcntr = project.projectKeyCounter++;
        const projectKeyCounter = project.projectKeyCounter;
        //const cardCounter = '1003';
        if (inputOption === undefined) {
          const newval = 'EPIC';
          const imgLocation = '../../images/'+newval+'.png';
          //console.log(inputOption, newval);
          dispatch(addCard(listID, inputHeader, inputName, inputText, cardCounter, projectKeyCounter, newval, imgLocation));
          setInputHeader("");
          setInputName("");
          setInputText("");
        } else {
          const check = optionValues.find((configg) => configg.value === inputOption)
          if (check === undefined) {
            ////
            const finder = project.cardConfigurations;
            let index = finder.indexOf(inputOption);

            //const ccnc = imgCounter++;
            //const count = imgCounter;
            //console.log(count);
            const imgLocation = '../../images/'+index+'.png';
            dispatch(addCard(listID, inputHeader, inputName, inputText, cardCounter, projectKeyCounter, inputOption, imgLocation));
            setInputHeader("");
            setInputName("");
            setInputText("");
          } else {
            const imgLocation = '../../images/'+inputOption+'.png';
            dispatch(addCard(listID, inputHeader, inputName, inputText, cardCounter, projectKeyCounter, inputOption, imgLocation));
            setInputHeader("");
            setInputName("");
            setInputText("");
          }
        }
      }
      // setting card text
    };

      // rendering text and list
     if ( isEditing === true) {
      return(
          /* inserting new text and sending to CollaborationForm */

        <CollaborationForm
          header={inputHeader}
          name={inputName}
          text={inputText}
          configtype={inputOption}
          onHeaderChange={handleheaderInputChange}
          onNameChange={handlenameInputChange}
          onTextChange={handletextInputChange}
          onOptnChange={handleOptionInputChange}
          list={list}
          closeForm={closeForm}
        >
          {/* add new list/card button */}
          <CollaborationButton value={list ? handleAddList : handleAddCard} onClick={list ? handleAddList : handleAddCard}  type="submit">
            {list ? "Add List" : "Add Card"}
          </CollaborationButton>
        </CollaborationForm>
      );
    } else {
        return(
        // adding another list or card
        <OpenFormButton onClick={() => setIsEditing(true)}>
          <AddIcon/>
          <p className="frmbtn" style={{ flexShrink: 0 }}>{buttonText}</p>
        </OpenFormButton>
        );
      }


  });


export default connect()(CollaborationCreate);
