import React, { useState } from "react";
import { editcardBlock, addCard } from "../actions";
import { connect } from "react-redux";

const ToggleButtonDisplay = ({ children, dispatch, id, parentID, childCheck, projectID, flagged}) => {

  // React state to manage visibility
  const [show, setShow] = useState(flagged);


  return (
    <div className="component-container">
      {show && children}
      <label>{flagged}</label>

    </div>
  );
};

export default connect()(ToggleButtonDisplay);
