import { CONSTANTS } from "../actions";
import { v4 as uuid } from 'uuid';
import axios from 'axios';

// dispatch actions of add list
export const addList = (title, listCounter, frID, secID, thrID ) => {
  return (dispatch, getState) => {
    //console.log(frID, secID, thrID);
    const projectID = getState().activeProject;
    var dat_e = new Date();
    dat_e = dat_e.toISOString().replace(/[^0-9]/g, "").slice(2, 8);
    const listCountID = "List"+dat_e+listCounter;
    const id = uuid();
    dispatch({
      type: CONSTANTS.ADD_LIST,
      payload: { title, projectID, id, listCounter, listCountID, frID, secID, thrID }
    });
  };
};

//dispatch action of drag and drop
export const sort = (
  droppableIdStart,
  droppableIdEnd,
  droppableIndexStart,
  droppableIndexEnd,
  draggableId,
  type
) => {
  return (dispatch, getState) => {
    const projectID = getState().activeProject;
    dispatch({
      type: CONSTANTS.DRAG_HAPPENED,
      payload: {
        droppableIdStart,
        droppableIdEnd,
        droppableIndexEnd,
        droppableIndexStart,
        draggableId,
        type,
        projectID
      }
    });
  };
};

// dispatch action of edit list
export const editTitle = (listID, newTitle) => {
  return {
    type: CONSTANTS.EDIT_LIST_TITLE,
    payload: {
      listID,
      newTitle
    }
  };
};

//dispatch action of delete list
export const deleteList = listID => {
  return (dispatch, getState) => {
    const projectID = getState().activeProject;
    return dispatch({
      type: CONSTANTS.DELETE_LIST,
      payload: {
        listID,
        projectID
      }
    });
  };
};

/*export const fetchList = () => {
    return (dispatch) => {
        axios.get('http://localhost:4000/lists/')
        .then(res => {
            for (let item of res.data) {
            const newID = `${item._id}`;
            const newList = {
              id: newID,
              title:item.title,
              cards: item.cards,
              board: item.board
            };
            console.log(newList);
            dispatch({ type: CONSTANTS.FETCH_LIST, payload:{newID, newList} })
          }
        })
    };
};
*/
