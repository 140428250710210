import { CONSTANTS } from "../actions";
import { v4 as uuid } from 'uuid';
import axios from 'axios';


//shows active boards here through id
export const setActiveOrganization = id => {
  return {
    type: CONSTANTS.SET_ACTIVE_ORGANIZATION,
    payload: id
  };
};
//creating new boards in backend
export const addOrganization = (title, orgid, orgObjID) => {
  //console.log(sendID);
  const id = uuid();
  var dat_e = new Date();
  const newCid = orgid++;
  dat_e = dat_e.toISOString().replace(/[^0-9]/g, "").slice(2, 8);
  const orid = "Org"+dat_e+newCid;
  //console.log(uid);
  return {
    type: CONSTANTS.ADD_ORGANIZATION,
    payload: { title, id, orgid, orgObjID }
  };
};
// editing existing organization
export const editOrganization = (organizationID, orgTitle) => {
  return {
    type: CONSTANTS.EDIT_ORGANIZATION,
    payload: { organizationID, orgTitle }
  };
};
// deleting organization
export const deleteOrganization = (organizationID) => {
  return (dispatch) => {
    return dispatch({
      type: CONSTANTS.DELETE_ORGANIZATION,
      payload: { organizationID }
    });
  };
};
export const softDeleteOrganization = (organizationID) => {
  return (dispatch) => {
    return dispatch({
      type: CONSTANTS.SOFT_DELETE_ORGANIZATION,
      payload: { organizationID }
    });
  };
};
export const restoreOrganization = (organizationID) => {
  return (dispatch) => {
    return dispatch({
      type: CONSTANTS.RESTORE_ORGANIZATION,
      payload: { organizationID }
    });
  };
};

// Fetching database stored results
export const fetchOrganization = () => {
    return (dispatch) => {
        axios.get('http://localhost:4000/organizations/')
        .then(res => {
            for (let item of res.data) {
            const newID = `${item._id}`;
            const projectCounter = item.projCounter;
            const newOrganization = {
              id: newID,
              organizationsID: item.organizationsID,
              title:item.title,
              projects: item.projects,
              softdelete: item.softdelete,
              Time: item.Time,
              projCounter: item.projCounter
            };
            //console.log(newOrganization);
            dispatch({ type: CONSTANTS.FETCH_ORGANIZATION, payload:{newID, projectCounter, newOrganization} })
          }
        })
        axios.get('http://localhost:4000/orgCounters/')
        .then(res => {
            for (let item of res.data) {
            const orgcounterID = `${item._id}`;
            /*const newOrgId = {
              counterID: item.orgID,
            };*/
            const newOrgId = item.orgID;
            //console.log(orgcounterID);
            dispatch({ type: CONSTANTS.FETCH_ORGCOUNTER, payload:{orgcounterID, newOrgId} })
          }
        })
        axios.get('http://localhost:4000/projects/')
        .then(res => {
            for (let item of res.data) {
            const newID = `${item._id}`;
            const newProject = {
              id: newID,
              title:item.title,
              lists: item.lists,
              cardConfigurations: item.cardConfigurations,
              organization: item.organization,
              projectKey: item.projectKey,
              projectCount: item.projectCount,
              projectKeyCounter: item.projectKeyCounter,
              projectsID: item.projectsID,
              listCounter: item.listCounter,
              cardCounter: item.cardCounter,
              dependentID: item.dependentID
            };
            //console.log(newProject);
            dispatch({ type: CONSTANTS.FETCH_PROJECT, payload:{newID, newProject} })
          }
        })
        axios.get('http://localhost:4000/lists/')
        .then(res => {
            for (let item of res.data) {
            const newID = `${item._id}`;
            const newList = {
              id: newID,
              title:item.title,
              cards: item.cards,
              board: item.board,
              listsID: item.listsID
            };
            //console.log(newList);
            dispatch({ type: CONSTANTS.FETCH_LIST, payload:{newID, newList} })
          }
        })
        axios.get('http://localhost:4000/cards/')
        .then(res => {
            for (let item of res.data) {
            const newID = `${item._id}`;
            const newCard = {
              id: newID,
              header:item.header,
              name:item.name,
              text:item.text,
              list: item.list,
              cardsID: item.cardsID,
              projectKeyCounter: item.projectKeyCounter,
              configType: item.configType,
              imgID: item.imgID,
              parentID: item.parentID,
              childCheck: item.childCheck,
              flagged: item.flagged

            };
            //console.log(newCard);
            dispatch({ type: CONSTANTS.FETCH_CARD, payload:{newID, newCard} })
          }
        })
        axios.get('http://localhost:4000/cardConfig/')
        .then(res => {
            for (let item of res.data) {
            const newID = `${item._id}`;
            const val = `${item.configValue}`;
            const valed = item.configValued;
            /*const allCardConfig = {
              id: newID,
              configValue: item.configValue
            };*/
            //console.log(allCardConfig);
            dispatch({ type: CONSTANTS.FETCH_CARDCONFIG, payload:{newID, val, valed} })
          }
        })


    };
};
