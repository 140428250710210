import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Icon from "@material-ui/core/Icon";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { restoreOrganization, fetchOrganization } from "../actions";
import Button from '@material-ui/core/Button';
//Commenting to run
//import { FaTrashRestore } from "@react-icons/all-files/fa/FaTrashRestore";
import { FaTrashRestore } from 'react-icons/fa';




// Thumbnails
const Show = styled.div`
  height: 90px;
  width: 255px;
  font-size: 18px;
  background: grey;
  padding: 10px;
  margin: 8px;
  left: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px grey;
`;

const OrganizationContainer = styled.div`
margin: 0 0 8px 0;
position: relative;
max-width: 20%;
word-wrap: break-word;
`;

const Title = styled.div`
  color: white;
  text-decoration: none;
`;


const DeleteButton = styled(Icon)`
  color: white;
  width: auto;
  position: absolute;
  display: none;
  left: 235px;
  right: 15px;
  bottom: 30px;
  opacity: 0.5;
  ${OrganizationContainer}:hover & {
    display: flex;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const useStyles = makeStyles({
  root: {
    minWidth: 90,
  },
  title: {
    fontSize: 15,
  },
  pos: {
    marginBottom: 12,
  },
});


const OrganizationShow = ({ dispatch, title, Time, organization, uid, id }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
/*  useEffect(() => {
    dispatch(fetchOrganization())
  }, []);
console.log('come here again');
*/




  const handleRestoreOrganization = () => {
    dispatch(restoreOrganization(id));
    setTimeout(() => window.location.reload(), 1);
  };


  return (
      <OrganizationContainer className={classes.root} variant="outlined">
        <DeleteButton fontSize="small" aria-controls="simple-menu" aria-haspopup="true" onClick={handleRestoreOrganization}>
          <FaTrashRestore/>
        </DeleteButton>


        <Show>
            <Title>
            <h2>{title}</h2><h5>Deleted at {Time}</h5>
          </Title>
        </Show>
      </OrganizationContainer>
  );
};

export default connect()(OrganizationShow);
