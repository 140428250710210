import React, {useEffect, useState,useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from '../../mystyle.module.scss';
import {useNavigate} from 'react-router-dom';
import {logoutUser, UnsetErrors,regUser,resendCode} from "../../actions/authActions.js";

function RegisterP(props){
  const dispatch = useDispatch();
  const user = useSelector(state=>state.auth.user);
  const isAuthenticated = useSelector(state=>state.auth.isAuthenticated);
  const isReg = useSelector(state=>state.auth.isReg);
  const resend = useSelector(state=>state.auth.resend);
  const errors = useSelector(state=>state.auth.errors);
  const [cCode, setcCode] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const clearLocalStorageTimeout = 5 * 60 * 1000; // 15 minutes in milliseconds
  // Retrieve the attempt count from local storage on component mount
  const storedAttempts = parseInt(localStorage.getItem('registrationAttempts')) || 0;
  const [registrationAttempts, setRegistrationAttempts] = useState(storedAttempts);
  let navigate =  useNavigate();

  useEffect(()=>{
    console.log('isau',isAuthenticated,isReg)
    if(isAuthenticated && isReg){
      console.log('user',user,isAuthenticated,isReg);
      navigate('/Dashboard');
    }

   /* if(!isAuthenticated && !isReg)
    {
      console.log('in not', isAuthenticated,isReg);
      navigate('/');
    }
    console.log('local storage',localStorage);*/
  });
  useEffect(()=>{
    localStorage.setItem('registrationAttempts', registrationAttempts.toString());

    if (errors==='InCorrect Code!') {
      // Increment the registration attempts
      console.log('saf',registrationAttempts,localStorage);
      setRegistrationAttempts((prevAttempts) => prevAttempts + 1);

    }

    // Set a timeout to clear localStorage after 15 minutes
    const clearLocalStorageTimeoutId = setTimeout(() => {
      localStorage.removeItem('registrationAttempts');
      setRegistrationAttempts(0);
    }, clearLocalStorageTimeout);

    // Cleanup the timeout on component unmount
    return () => clearTimeout(clearLocalStorageTimeoutId);
  },[errors])
  const handleChange = (e) => {
        e.preventDefault();
        setcCode(e.target.value);
        setSubmitted(false);
       // console.log(cCode);
   /*const inputValue = e.target.value;

    // Use a regex to allow only numeric input
    const numericInput = inputValue.replace(/[^0-9]/g, '');

    setNumberValue(numericInput);*/
  }
  const handleClick= (e) =>{
      e.preventDefault();
      dispatch(UnsetErrors());
      const userData= {
        usrId : user.usrId,
        usrEmail: user.email,
        usrFname: user.userfname,
        usrLname: user.userlname,
        cCode : cCode,
        //errors: errors
      }
      setSubmitted(true);
      //console.log(userData,'handlesub');
      dispatch(regUser(userData));
      setcCode('');

    }

    const handleResend= (e) =>{
      e.preventDefault();
      //dispatch(UnsetErrors());
      const userData= {
        usrId : user.usrId,
        usrEmail: user.email,
        usrFname: user.userfname,
        usrLname: user.userlname,
      }
      setSubmitted(false);
      //console.log(userData,'handlesub');
      dispatch(resendCode(userData));
      setRegistrationAttempts(0);
      setcCode('');
    }
    const onLogoutClick = (e) => {
      e.preventDefault();
     // console.log('sses',session);
      const userData= {
        user : user.email,
      }
      //console.log('inlogt');
      dispatch(logoutUser(userData));
      localStorage.clear();
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      navigate('/');
     };


return (
  <>
<button onClick = {onLogoutClick} className={styles.btnL}> Logout </button>
<div style={{float:"right", padding:"1em"}}><p className="flow-text grey-text text-darken-1"> Hey {user.userfname} {user.userlname}</p></div>
{/*<div  style={{align:"center", padding:"1em"}} > <h2>
   <p><b>Sg Online Interaction Tool</b> </p>     </h2>
</div>*/
}
<div  style={{align:"center", padding:".1em"}} > <h2>
       </h2>
</div>
<div style={{ flexDirection: "row",justifyContent:'center' }} >
    <div className={styles.container}>
      <form name="regP" method="post">
        <div className={styles.container}>
          { (submitted && errors &&
              <div className="help-block" style={{ color: 'firebrick' }}> {JSON.stringify(errors).slice(1, -1)}  </div>)||
            (registrationAttempts===4) &&
            <div className="help-block" style={{ color: 'firebrick' }}>
               <span>You have reached Max attempts! <br /> Try Resend Code <br />  Try Again!</span></div>
          }
        <label className={styles.lblM}> Registeration Code: </label>
        <input type="text" name="cCode" value={cCode} placeholder='Enter Registration Code' className={styles.inM}
            onChange={handleChange} />
            <button onClick={handleClick} disabled={registrationAttempts >= 4}className={styles.btnM} style={{ height: "1.8em" }}> Register </button>

        <div>   <button onClick={handleResend} className={styles.btnM} style={{ height: "1.8em", marginTop:"1em" }}> Resend Code </button>
        </div>
        </div>

      </form>
    </div>
  </div>
  </>
  );
}
export default RegisterP;
