import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addOrganization, fetchOrganization } from "../actions";
import OrganizationThumbnail from "./OrganizationThumbnail";
//import OrganizationShow from "./OrganizationShow";
//import Icon from "@material-ui/core/Icon";
//import { makeStyles } from '@material-ui/core/styles';
//import OrganizationForm from "./OrganizationForm";
import { Input, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from 'reactstrap';
//import Button from "@material-ui/core/Button";


const Title = styled.h4`
  text-decoration: none;
`;

const Home = ({ organizations, orgcounters, organizationOrder, orgcounterOrder, organizationID, dispatch, newOrgId }) => {
  // this is the home site that shows you your organizations and you can also create a Organization here.
  const [newOrganizationTitle, setNewOrganizationTitle] = useState("");
  // Collapse isOpen State
  const [isOpen, setIsOpen] = React.useState(false);

  //var dat_e = Date().slice(0, 21);
  //console.log(dat_e);
  function refreshPage() {
    window.location.reload(false);
  }

  // function to send request to fetch data from database
  useEffect(() => {
    dispatch(fetchOrganization())
  }, []);

  const handleChange = e => {
    setNewOrganizationTitle(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (orgcounters.orgcounterID === undefined) {
      refreshPage();
    }
    const setID = orgcounters.newOrgId++;
    const orgObjID = orgcounters.orgcounterID;
    const orgid = setID;
    dispatch(addOrganization(newOrganizationTitle, orgid, orgObjID));
    setNewOrganizationTitle("");
    //
  };
  const renderButton = () => {
    return (
      <div style={{display: 'block', width: "100%"}}>
        <Navbar color="light" light expand="lg">
          <NavbarBrand href="/" align='left'></NavbarBrand>
          <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <Link  className='btnadd' align="left" to="/Organization/SoftDeleted/" style={{ textDecoration: 'none', color: 'black'}}>Soft Deleted</Link>
            </Nav>
          </Collapse>
        </Navbar>
      </div>

    );
  }

  const renderOrganizations = () => {
    return organizationOrder.map(organizationID => {
      const organization = organizations[organizationID];
//      <div className="subContainer">
//        </div>
  //    if (organization.softdelete === false) {
      return (
        <Title
          className="subContainer"
          softdelete={organization.softdelete}
          key={organizationID}
          style={{ textDecoration: "none" }}
          >
          <OrganizationThumbnail {...organization} />
        </Title>
      );

//    }
    });
  };

  const renderCreateOrganization = () => {
    return (
      <div className="createInput" >
        <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
          {/*<FormText className="createinput">Create New Organization</FormText>*/}
          <div>
            <Input
              required
              className="orginputbox"
              onChange={handleChange}
              value={newOrganizationTitle}
              placeholder="create new Organization"
              type="text"
            />

          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="mainContainer">
        {renderButton()}
        {renderOrganizations()}
      {renderCreateOrganization()}

    </div>

  );
};

const mapStateToProps = state => ({
  organizations: state.organizations,
  orgcounters: state.orgcounters,
  organizationOrder: state.organizationOrder
});

export default connect(mapStateToProps)(Home);
