import React, { useState } from "react";
import styles from "../org.project.scss";
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Icon from "@material-ui/core/Icon";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { editProjectTitle, deleteProject/*, softDeleteProject*/ } from "../actions";
import ProjectForm from "./ProjectForm";
import CollaborationButton from "./CollaborationButton";
import { Link } from "react-router-dom";
import {Form, Input} from 'reactstrap';
//commenting to run again
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ThumbnailPopup from './ThumbnailPopup';



/*/ Thumbnails
const Thumbnail = styled.div`
  height: 120px;
  width: 200px;
  font-size: 25px;
  background: grey;
  padding: 10px;
  margin: 8px;
  left: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 2px 4px grey;
`;
*/
const ProjectContainer = styled.div`
margin: 0 0 8px 0;
max-width: 100%;
word-wrap: break-word;
`;

const Title = styled.h4`
  color: black;
  text-decoration: none;
`;
const EditButton = styled(Icon)`
  color: black;
  position: relative;
  display: none;
  left: 102%;
  bottom: 150%;
  opacity: 0.5;

  ${ProjectContainer}:hover & {
    display: flex;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const DeleteButton = styled(Icon)`
  color: black;
  position: relative;
  display: none;
  left: 95%;
  top: 150%;
  opacity: 0.5;
  ${ProjectContainer}:hover & {
    display: flex;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const useStyles = makeStyles({
  root: {
    minWidth: 180,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


const ProjectThumbnail = ({ dispatch, title, id, match, organizationID, projectKey, projectCount }) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [projTitle, setTitle] = useState(title);
  const [projKey, setProjectKey] = useState(projectKey);
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const { organizationID } = match.params;
  //console.log(id);

  // on click close editing
  const closeForm = e => {
    setIsEditing(false);
  };

  // saving new data
  const handleTitleChange = e => {
    //setNewProjectTitle(e.target.value);
    setTitle(e.target.value);
    //console.log(e.target.value);
  };
  const handleKeyChange = e => {
    //setNewProjectKey(e.target.value);
    const result = e.target.value.replace(/[^a-z0-9]/gi, '');

    setProjectKey(result);
  };


  const handleDeleteProjects = () => {

      confirmAlert({
        title: 'Confirm Delete',
        message: 'Are you sure to want to delete.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => handleDeleteProject()
          },
          {
            label: 'No',
            onClick: () => closeForm()
          }
        ]
      });
    }
  const handleDeleteProject = () => {
    dispatch(deleteProject(id, organizationID) );
  //  setTimeout(() => window.location.reload(), 1);
  };

  const handleSoftDeleteProject = () => {
    /*dispatch(softDeleteProject(id));
    setTimeout(() => window.location.reload(), 1);*/
  };

  const saveProject = e => {
    e.preventDefault();
    if (projTitle === "" || projTitle === " ") {
      alert("Please enter Title to proceed Further");
    } else {
      // dispatch values of card which is editing
      //console.log(id);
      dispatch(editProjectTitle(id, projTitle, projKey));
      setIsEditing(false);
    }
  };
  //console.log(title);
  if (isEditing === true) {
    return (
      <div>
        {/*<OpenFormButton onClick={() => setIsEditing(true)}>
          <p style={{ flexShrink: 0 }}>Add New Project</p>
        </OpenFormButton>*/}
        <ThumbnailPopup
             content={<>
               <b>Edit Project</b>

               <Form style={{ textAlign: "center" }}>
                 <div>
                 <p style={{ marginTop: "20px", textAlign: "left" }} >Project Name</p>
                   <Input
                     required
                     className="inputbox"
                     onChange={handleTitleChange}
                     title={projTitle}
                     value={projTitle}
                     type="text"
                   />
                   <p style={{ textAlign: "left" }} >Project Key</p>
                   <Input
                     required
                     className="keybox"
                     onChange={handleKeyChange}
                     maxLength={6}
                     title={projKey}
                     value={projKey}
                     type="text"
                   />
                 </div>
               </Form>
               <Button variant="success" onClick={saveProject}>Save</Button>{' '}
             </>}
             closeForm={closeForm}
           />

      </div>

    );
  } else {
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

  return (

      <ProjectContainer className={classes.root} variant="outlined">
        <div className="row">
          <div className="column">
            <div className="thumbnail">
              <EditButton
                onMouseDown={() => setIsEditing(true)}
              >
                <EditIcon />
              </EditButton>
              <DeleteButton  aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <DeleteIcon />
              </DeleteButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClick={handleClose}
              >
                <MenuItem onClick={handleSoftDeleteProject}>Soft Delete</MenuItem>
                <MenuItem onClick={handleDeleteProjects}>Delete</MenuItem>
              </Menu>
              <Link
                to={`/${id}/Project/`}
                style={{ textDecoration: 'none', marginTop: '4px' }}
              >
                <Title numberOfLines={1}>
                  {title}
                </Title>
              </Link>
            </div>
          </div>
        </div>
      </ProjectContainer>
  );
  }
};


      {/*/ setting up new text and sending it in CollaborationForm file
      //<ProjectForm title={projTitle} onTitleChange={handleTitleChange} closeForm={closeForm}>
      //  <CollaborationButton onClick={saveProject}>Save</CollaborationButton>
      //</ProjectForm>*/}
export default connect()(ProjectThumbnail);
