export const GET_ERRORS = "GET_ERRORS";
export const UNSET_ERRORS = "UNSET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_USER_LOGOUT = "SET_CURRENT_USER_LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const USER_UREG = "USER_UREG";
export const RESEND_CODE = "RESEND_CODE";
export const SET_INVALID_USER = "SET_INVALID_USER";
export const SET_ALREADY_SENT = "SET_ALREADY_SENT";
