import React, { useState } from "react";
import styled from "styled-components";
import Icon from "@material-ui/core/Icon";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { editOrganization, deleteOrganization, softDeleteOrganization } from "../actions";
//import OrganizationForm from "./OrganizationForm";
//import CollaborationButton from "./CollaborationButton";
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from 'react-bootstrap/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
//import styles from "../org.project.scss";
import ThumbnailPopup from './ThumbnailPopup';
import {Form, Input} from 'reactstrap';


/*/ Thumbnails
const Thumbnail = styled.div`
  height: 180px;
  width: 200px;
  font-size: 25px;
  background: grey;
  padding: 10px;
  margin: 8px;
  left: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  border-radius: 15px;
  box-shadow: 0 2px 4px grey;
`;
*/
const OrganizationContainer = styled.div`
margin: 0 0 8px 0;
max-width: 100%;
word-wrap: break-word;
`;

const Title = styled.h4`
  color: black;
  text-decoration: none;
`;

const EditButton = styled(Icon)`
  color: black;
  position: relative;
  display: none;
  left: 102%;
  bottom: 150%;
  opacity: 0.5;
  ${OrganizationContainer}:hover & {
    display: flex;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const DeleteButton = styled(Icon)`
  color: black;
  position: relative;
  display: none;
  left: 95%;
  top: 150%;
  opacity: 0.5;
  ${OrganizationContainer}:hover & {
    display: flex;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const useStyles = makeStyles({
  root: {
    minWidth: 180,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


const OrganizationThumbnail = ({ dispatch, title, organization, uid, id }) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [orgTitle, setTitle] = useState(title);
  const [anchorEl, setAnchorEl] = React.useState(null);

  //console.log(uid);

  // on click close editing
  const closeForm = e => {
    setIsEditing(false);
  };

  // saving new data
  const handleTitleChange = e => {
    //setNewOrganizationTitle(e.target.value);
    setTitle(e.target.value);
    //console.log(e.target.value);
  };

  const handleDeleteOrganizations = () => {

      confirmAlert({
        title: 'Confirm Delete',
        message: 'Are you sure to want to delete.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => handleDeleteOrganization()
          },
          {
            label: 'No',
            onClick: () => closeForm()
          }
        ]
      });
    }
  const handleDeleteOrganization = () => {
    dispatch(deleteOrganization(id) );
    setTimeout(() => window.location.reload(), 1);
  };

  const handleSoftDeleteOrganization = () => {
    dispatch(softDeleteOrganization(id));
    setTimeout(() => window.location.reload(), 1);
  };

  const saveOrganization = e => {
    e.preventDefault();
    // dispatch values of card which is editing
    dispatch(editOrganization(id, orgTitle));
    setIsEditing(false);
  };
  if (isEditing === true) {
    return (
      <div>
      <ThumbnailPopup
           content={<>
             <b>Edit Organization</b>
             <Form style={{ textAlign: "center" }}>
               <div>
               <p style={{ marginTop: "20px", textAlign: "left" }} >Organization Name</p>
                 <Input
                   required
                   className="inputbox"
                   onChange={handleTitleChange}
                   title={orgTitle}
                   value={orgTitle}
                   type="text"
                 />
                 </div>
               </Form>
               <Button variant="success" onClick={saveOrganization}>Save</Button>{' '}

             </>}
             closeForm={closeForm}
           />
      </div>
      // setting up new text and sending it in CollaborationForm file
    /*  <OrganizationForm title={orgTitle} onTitleChange={handleTitleChange} closeForm={closeForm}>
        <CollaborationButton onClick={saveOrganization}>Save</CollaborationButton>
      </OrganizationForm>*/
    );
  } else {
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

  return (

      <OrganizationContainer className={classes.root} variant="outlined">
        <div className="row">
          <div className="column">
            <div className="thumbnail">
              <EditButton
                onMouseDown={() => setIsEditing(true)}
              ><EditIcon/></EditButton>
              <DeleteButton  aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <DeleteIcon />
              </DeleteButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClick={handleClose}
              >
                <MenuItem onClick={handleSoftDeleteOrganization}>Soft Delete</MenuItem>
                <MenuItem onClick={handleDeleteOrganizations}>Delete</MenuItem>
              </Menu>
              <Link
                to={`/${id}`}
                style={{ textDecoration: 'none' }}
              >
                <Title>
                  {title}
                  </Title>
              </Link>

            </div>
          </div>
        </div>
      </OrganizationContainer>
  );
  }
};

export default connect()(OrganizationThumbnail);
